import RequestData from "../RequestData";
import moment from "moment";

export default class RequestCruises extends RequestData {
  constructor() {
    super();

    this.DATE_FORMAT = "MMMM D, YYYY";
    this.TODAY = moment().format(this.DATE_FORMAT);

    this.DATA = {
      date: localStorage.getItem("customDate")
        ? localStorage.getItem("customDate")
        : this.TODAY,
    };

    this.startDate = moment(this.DATA.date, "MMMM D, YYYY").format("YYYY-M-D");
    // this.endDate = moment(startDate, "YYYY-M-D")
    //   .add(0, "days")
    //   .format("YYYY-M-D");

    this.host = "https://www.porttb.com";
    this.path = `/?a=eventtypes.api&resource=events&group_id=a7970728-de1d-445f-865b-e4ccecc4e3ba&EndDate=${this.startDate}&StartDate=${this.startDate}`;
  }

  getCruises(callback) {
    this.DATA.date = localStorage.getItem("customDate");

    this.getData("cruises#" + this.startDate, (data) => {
      callback(data);
    });
  }
}
