import React, { Component } from 'react';
import {
  View,
  TouchableOpacity,
  Text
} from 'react-native';

import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import PropTypes from 'prop-types';

import styles from './CustomTimerStyles';

import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import lightBlue from "@material-ui/core/colors/lightBlue";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import toUnicodeVariant from "../ToUnicodeVariant";

import ReactGA from "react-ga4";

export default class CustomTimer extends Component {
    constructor(props){
        super(props);

        let time = new Date();
        var cur_time = moment(time.toLocaleTimeString(), 'h:mm:ss A');
        /** convert to 24hr format */
        this.CUR_TIME = moment(cur_time.format('HH:mm'), 'HH:mm');   
        
        this.DATA = {
          customTimer: JSON.parse(localStorage.getItem('customTimer')),
        };

        this.state = {
            time: this.DATA.customTimer ? moment(this.DATA.customTimer.time, "hh:mm:ss a") : '',
            duration: ''
        }

        this.preventBehavior = (e) => {
          e.preventDefault(); 
        };
        
        document.addEventListener("touchmove", this.preventBehavior, {passive: false});
    }

    static propTypes = {
        onSlideSelected: PropTypes.func.isRequired
    }

    onSet = () => {
      if (this.state.time != '')
        if(confirm("Set your timer to " + this.state.time.format('h:mm A') + "?\nTime remaining: " + this.state.duration) &&
        confirm("In addition, by selecting 'Ok', you understand that this is "+ toUnicodeVariant('NOT', 'bold sans', 'bold') +" an audible alarm. You must have this site opened in order to be notified when the timer is up.")) {
          localStorage.setItem('customTimer', JSON.stringify({
            time: this.state.time.format("h:mm a"),
            duration: this.state.duration,
            disabled: false
          }));

          // console.log(moment.duration(this.state.time.format("H:mm")).asHours())

          ReactGA.event({
            category: "select_timer",
            action: "select custom timer",
            value: moment.duration(this.state.time.format("H:mm")).asHours()
          });

          //console.log(this.state.time.format("h:mm a"));

          localStorage.removeItem('cruise');
          localStorage.removeItem('boarding');

          this.props.onSlideSelected('tours');
        }
    }

    onReset = () => {
      if (confirm("Are you sure that you want to reset your timer?")) {
        this.setState({time: '', duration: '', disabled: false});
        localStorage.removeItem('customTimer');
      }
    }

    componentWillUnmount() {
      document.removeEventListener("touchmove", this.preventBehavior, {passive: false});
    }

    render() {

        var changeTime = (time) => {
          /** convert to 24hr format */
          var time_ = moment(time.format('HH:mm'), 'HH:mm');
          var duration = moment.utc(time_.diff(this.CUR_TIME)).format("H:mm");
          
          this.setState({time: time, duration: duration, disabled: false}); 
        }
        
        return(
            <View style={styles.container}>
                <View style={styles.timerContainer}>

                    <View style={{flexDirection: "row", margin: 30}}>
                        <Text style={[styles.centerText]}>
                            SET CUSTOM TIMER
                        </Text>

                        <TouchableOpacity style={{top: 5}} onPress={() => {this.props.onSlideSelected('departure-timer')}}>
                            <MaterialCommunityIcons name="close-thick" size={30} color="white"/>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.cardContainer}>
                        <View style={styles.clockContainer}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <ThemeProvider theme={materialTheme}>
                                    <TimePicker
                                    autoOk
                                    variant="static"
                                    openTo="hours"
                                    value={moment(this.state.time, 'HH:mm')}
                                    onChange={(time) => changeTime(time)}
                                    inputVariant="standard"
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                        </View>

                        
                        <View style={{flexDirection: "row"}}>
                          <TouchableOpacity style={styles.set} onPress={() => this.onSet()}>
                              <Text style={styles.setText}> SET </Text>
                          </TouchableOpacity>
                          
                          <TouchableOpacity style={styles.reset} onPress={() => this.onReset()}>
                            <Text style={styles.resetText}> RESET </Text>
                          </TouchableOpacity>
                        </View>
                    </View>
                    
                </View>
            </View>
        )
    }
}

const materialTheme = createTheme({
    overrides: {
      MuiPickersBasePicker: {
        container: {
        },
  
        pickerView: {
          alignSelf: "center",
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "rgba(41, 100, 147, 0.9)",
          borderRadius: 5,
        }
      },
      MuiPickersModal: {
        dialogAction: {
          color: lightBlue["400"],
        },
      },
    },

    palette: {
		primary: {
			main: "rgba(41, 100, 147, 0.9)"
		},
		secondary: {
			main: "rgba(41, 100, 147, 1)",
		},
	},
  });