import { createSlice, configureStore } from '@reduxjs/toolkit'

/** DEPRACATED, GOOGLE API NO LONGER IN USE FOR REQUESTING DATA */
export default class PlaceData {
    constructor(map, maps) {
        this.mapAPI = maps;
        this.map = map;

        this.placesService = new google.maps.places.PlacesService(this.map);        
    }

    addressToID(location, callback) {
        const request = {
            query: location,
            fields: ["place_id"]
        }
        let places = localStorage.getItem('placeIds') != undefined ? JSON.parse(localStorage.getItem('placeIds')) : [];
        let index = places.findIndex((e) => e.location == location);

        if (index != -1) {
            callback(places[index]);
            ////console.log("passed cached data: " + places[index]);
        } else {
            this.placesService.findPlaceFromQuery(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results) {

                    ////console.log("passed new data: " + results[0]);

                    let newItem = {
                        location: location,
                        place_id: results[0].place_id
                    }

                    places.push(newItem);
                    localStorage.setItem('placeIds', JSON.stringify(places));
                    
                    callback(results[0]);
                } else {
                    console.error(status);
                }
            });
        }
        
    }

    addressToLocation(location) {
        const id = this.getDetails(location);

        return id.geometry.location;
    }

    getDetails(location, fields, callback) {
        this.addressToID(location, (results) => {

            var request = {
                placeId: results.place_id,
                fields: fields
            }
            
            this.placesService.getDetails(request, (place, status) => {
                if (
                    status === google.maps.places.PlacesServiceStatus.OK &&
                    place 
                ) {
                    callback(place);
                } else {
                    console.error(status);
                }
            });
        });
    }

    getPictures(location, amount, callback) {
        this.getDetails(location, ['photo'], (place) => {
            let imgs = [];
            for (let i = 0; i < amount; i++) {
                var img = place.photos[i].getUrl({maxWidth: 500, maxHeight: 500});
                imgs.push(img);
            }

            callback(imgs);
        });  
    }

}