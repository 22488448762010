import React, { Component, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableHighlight,
  TouchableOpacity,
  Animated,
  SafeAreaView,
  FlatList,
} from "react-native";

import GoogleMap from "google-map-react";
import { Link, Route, Navigate } from "react-router-dom";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";

import Tutorial from "./components/tutorial/Tutorial";
import Drawer from "./components/drawer/Drawer";
import Time from "../Time";
import User from "./components/User";
import PlaceData from "./components/PlaceData";
import RequestDestinations from "../Front/RequestDestinations";
import {NewLink} from "../../UrlHandler";

import ReactGA from "react-ga4";

import styles from "./MapStyles";
import "./components/Popup.css";

export default class Map extends Component {
  constructor(props) {
    super(props);
    this.API_KEY = "AIzaSyAd5gfgVEH8p6XaG1PSvq21ovyQSZD-a74";

    this.destinations = new RequestDestinations();

    this.DATA = {
      timer:
        localStorage.getItem("cruise") != undefined
          ? JSON.parse(localStorage.getItem("cruise"))
          : {
              cruiseline: "none",
              cruise: "none",
              id: 0,
            },
      boarding:
        localStorage.getItem("cruise") != undefined
          ? JSON.parse(localStorage.getItem("boarding"))
          : {
              boarding: "-- : -- --",
              time_remaining: "Select Timer",
            },
      tour: localStorage.getItem("tour"),
      customTimer: JSON.parse(localStorage.getItem("customTimer")),
      selectedDestinations: JSON.parse(
        localStorage.getItem("selectedDestinations")
      ),
      contentful: JSON.parse(localStorage.getItem("contentful"))
        ? JSON.parse(localStorage.getItem("contentful"))
        : {
            terminals: [],
            destinations: [],
            raw: {},
          },
      viewedTutorial: JSON.parse(localStorage.getItem("viewedTutorial")) ? JSON.parse(localStorage.getItem("viewedTutorial")) : {"ybor": false, "tampa": false}
    };
    //console.log(this.DATA.contentful);

    // Location data
    this.DESTINATIONS = this.createPlaces();

    this.FILTERS = require("../../data/filters.json");

    this.GOOGLESTYLE = require("../../data/googleStyle.json");

    this.FILTERED_DATA = this.DESTINATIONS;
    //console.log(this.FILTERED_DATA);
    this.PARKING = require("../../data/parking.json");

    this.RIVERWALK = require("../../data/routes/riverwalk.json");
    this.YBOR = this.DATA.contentful.raw.includes.Entry[1].fields.route;
    //console.log(this.DATA.contentful.raw.includes.Entry[1].fields.route);
    this.SELECTED_ROUTE =
      this.DATA.tour === "ybor" ? this.YBOR : this.RIVERWALK;
    this.TERMINALS = this.DATA.contentful.terminals;

    this.contentful = require("contentful");
    this.client = this.contentful.createClient({
      space: "2e3h70gj067y",
      accessToken: "T13LSz_CfYE6BCuZtnveKoVkss-Ss_5UidKjkWBzTK0",
    });

    // Initial region
    this.CameraLocation =
      this.DATA.tour == "ybor"
        ? {
            latitude: 27.956,
            longitude: -82.44018,
            latitudeDelta: 0.022,
            longitudeDelta: 0.0111,
            zoom: 15
          }
        : {
            latitude: 27.95367,
            longitude: -82.45847,
            latitudeDelta: 0.0102,
            longitudeDelta: 0.0221,
            zoom: 14
          };

    this.popups = [];
    // //console.log(this.DATA.selectedDestinations)
    this.state = {
      markers: [],
      filters: [],
      redRoute: {},

      tour: this.DATA.tour,
      destinations: this.DATA.selectedDestinations
        ? this.DATA.selectedDestinations
        : [], // list of selected destinations/routes
      selectedDestination: {},

      // Height of destination selector block
      ySize: new Animated.Value(240),
      destinationsOpacity: new Animated.Value(1),
      detailsOpacity: new Animated.Value(0),
      showDetails: false,
      showCards: true,
      pictures: [],
      tutorialOpened: !this.DATA.viewedTutorial[this.DATA.tour] ? true : false,

      // Initial filter
      filter: "popular",
    };

    if (this.DATA.viewedTutorial[this.DATA.tour] == false || this.DATA.viewedTutorial[this.DATA.tour] == undefined) {
      localStorage.setItem("viewedTutorial", JSON.stringify({"ybor": this.DATA.tour == "ybor" ? true : false, "tampa": this.DATA.tour == "tampa" ? true : false}));
      //console.log("flase")
    }
  }

  createPlaces = () => {
    let destinations = [];
    let data = this.DATA.contentful.raw;
    let places = this.destinations.filterByTour(
      this.DATA.tour,
      this.DATA.contentful.destinations,
      data.includes.Entry
    );
    // places.push(this.DATA.contentful.terminals);

    //console.log(data);

    for (let i = 0; i < places.length; i++) {
      let place = places[i];

      try {
        let images = place.fields.images.map(image => this.destinations.getPhotoUrlById(image.sys.id, this.DATA.contentful.raw.includes.Asset));

        var url = images[0];

        const name = place.fields.title
          ? place.fields.title
          : "Title not found.";
        const type = place.fields.type;
        const description = place.fields.description
          ? place.fields.description.content[0].content[0].value
          : "Description not found.";
        const hours = place.fields.hours
          ? place.fields.hours.content[0].content[0].value
          : "Hours not found.";
        const address = place.fields.address
          ? place.fields.address.content[0].content[0].value
          : "Address not found.";
        const phoneNumber = place.fields.phoneNumber
          ? place.fields.phoneNumber
          : "Phone number not found.";
        const website = place.fields.website
          ? place.fields.website
          : "";
        const thumbnail = url ? url : "";
        const location = place.fields.location;
        const placeId = place.fields.placeId ? place.fields.placeId : "";
        const id = place.sys.id;

        var destination = {
          name: name,
          type: type, // Required
          description: description,
          hours: hours,
          address: address,
          phoneNumber: phoneNumber,
          website: website,
          images: images ? images : [""],
          thumbnail: thumbnail,
          location: location, // Required
          placeId: placeId,
          id: id, // Required
        };

        destinations.push(destination);
      } catch (e) {
        console.warn(e);
      }
    }
    return destinations;
  };

  /**  runs when the user presses the plus button on the details page */
  addDestination = (item) => {
    let _item = this.state.selectedDestination;
    let newItem = item != null ? item : _item;
    let index = newItem.index;

    this.setDestination(newItem);
    this._drawer.closeDestinationDetails();
  };

  /**  runs when the user presses the 'remove' button on the details page */
  removeDestination = (item) => {
    let _item = this.state.selectedDestination;
    let newItem = item != null ? item : _item;
    let index = newItem.index;

    ////console.log("closed: " + newItem)

    this.setDestination(newItem);
    // this._drawer.closeDestinationDetails();
  };

  setDestination = async (item) => {
    let dest = [];
    dest.push(...this.state.destinations);

    let index = dest.indexOf(item.id);

    if (dest.includes(item.id)) {
      dest.splice(index, 1);
    } else {
      dest.push(item.id);
      ReactGA.event({
        category: "destinations",
        action: "added " + item.name + " to tour",
      });
    }
    
    await this.setState({ destinations: dest });

    localStorage.setItem(
      "selectedDestinations",
      JSON.stringify(this.state.destinations)
    );

    switch (item.placeId) {
      case "parking":
      break;

      case "trolly":
      break;
      
      default:
        const color = this.state.destinations.includes(item.id) ? "red" : "blue";
        const svgMarker = {
          url: require("./img/icon-" + color + "-location-pin.png"),
          scaledSize: new google.maps.Size(50, 60),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(50 / 2, 60 / 2 + 15),
        };
    
        const markerIndex = this.state.markers.findIndex(
          (x) => x.destination == item
        );
    
        // if (this.state.marker.destination.type.includes(this.state.filter))
        this.state.markers[markerIndex].marker.setIcon(svgMarker);
      break;
    }

    this.updateSelectedRoute();
  };

  /** Set detail information and set images when a card is pressed */
  openDetails = async (item) => {
    await this.setPlace(item);
    //console.log(item)
    ReactGA.event({
      category: "destinations",
      action: "viewed " + item.name,
    });
  };

  onMarkerPressed = async (marker, index) => {
    let location = marker.location;

    this.setState({ details: this.FILTERED_DATA[index] });
  };

  filterData = () => {
    var data = this.DESTINATIONS;

    this.FILTERED_DATA = data.filter((item) => {
      return (
        item.type.includes(this.state.filter) ||
        this.state.destinations.includes(item.id) ||
        item.type.includes("none")
      );
    });

    if (this._drawer) this._drawer.FILTERED_DATA = this.FILTERED_DATA;
  };

  onFilterSelect = async (filter) => {
    if (this.state.filter !== filter.type) {
      await this.setState({ filter: filter.type });
      await this.addMarkers();
    }
  };

  renderRoute = () => {
    if (this.DATA.tour == "ybor") {
    /** initial route */
    var lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeColor: "#00a1e1",
      strokeOpacity: 1,
      scale: 4
    };

    let path1 = [...this.SELECTED_ROUTE.coordinates];
    let path2 = [...this.SELECTED_ROUTE.coordinates];

    let route = new this.mapAPI.Polyline({
      path: path1.splice(0, 50),
      geodesic: false,
      icons: [{
        icon: lineSymbol,
        offset: '0',
        repeat: '20px'
      }],
      // strokeColor: "#00a1e1",
      strokeOpacity: 0,
      // strokeWeight: 4,
    });
    route.setMap(this.map);

    let route1 = new this.mapAPI.Polyline({
      path: path2.splice(50, this.SELECTED_ROUTE.coordinates.length - 50),
      geodesic: false,
      strokeColor: "#00a1e1",
      strokeOpacity: 1,
      strokeWeight: 4,
    });
    route1.setMap(this.map);
    } else {

    let route = new this.mapAPI.Polyline({
      path: this.SELECTED_ROUTE.coordinates,
      geodesic: false,
      strokeColor: "#00a1e1",
      strokeOpacity: 1,
      strokeWeight: 4,
    });
    route.setMap(this.map);
    }

  };


  getDestinationsFromIds = () => {
    let destinations = [];
    for (let i = 0; i < this.FILTERED_DATA.length; i++) {
      let destination = this.FILTERED_DATA[i];

      if (this.state.destinations.includes(destination.id)) {
        destinations.push(destination);
      }
    }
    return destinations;
  };

  renderSelectedRoute = () => {
    const route = new this.mapAPI.Polyline({
      path: [],
      geodesic: true,
      strokeColor: "red",
      strokeOpacity: 1,
      strokeWeight: 3,
    });

    route.setMap(this.map);
    this.setState({
      redRoute: {
        route: route,
        routeRaw: [],
      },
    });

    this.updateSelectedRoute();
  };

  updateSelectedRoute = () => {
    function getBoundLeft(dests) {
      let leftBound = dests[0];
      if (dests.length > 1) {
        for (let i = 0; i < dests.length; i++) {
          if (dests[i].location.lon < leftBound.location.lon) {
            leftBound = dests[i];
          }
        }
        // //console.log(leftBound)
        return leftBound.location;
      } else if (dests.length == 1) {
        return dests[0].location;
      } else {
        return -1;
      }
    }

    function getClosestCheckpoint(location, checkpoints) {
      let closestCheckpoint = checkpoints[0];
      let closestDistance = 100; // Arbitrary

      for (let i = 0; i < checkpoints.length; i++) {
        let checkpoint = checkpoints[i];
        let distance = Math.sqrt(
          Math.pow(location.lat - checkpoint.lat, 2) +
            Math.pow(location.lon - checkpoint.lng, 2)
        );

        if (distance < closestDistance) {
          closestDistance = distance;
          closestCheckpoint = checkpoint;
        }
      }

      return closestCheckpoint;
    }

    function createNewRoute(start, end, route) {
      let newRoute = [];

      for (let i = 0; i < route.length; i++) {
        if (route[i] != end) {
          newRoute.push(route[i]);
        } else {
          return newRoute;
        }
      }
    }

    if (this.state.destinations.length > 0) {
      try {
        let selectedDests = this.getDestinationsFromIds();
        let bound = getBoundLeft(selectedDests);
        let checkpoint = getClosestCheckpoint(
          bound,
          this.SELECTED_ROUTE.coordinates
        );
        let newRoute = createNewRoute(
          this.SELECTED_ROUTE.coordinates[0],
          checkpoint,
          this.SELECTED_ROUTE.coordinates
        );

        if (this.state.redRoute.route) {
          // //console.log("set path");
          this.state.redRoute.route.setPath(newRoute);
        }
      } catch (e) {
        //console.log(e);
      }
    } else {
      if (this.state.redRoute.route) {
        // //console.log("set path")
        this.state.redRoute.route.setPath([]);
      }
    }
  };

  addMarkers = () => {
    /** reset markers */
    this.state.markers.map((marker) => marker.marker.setMap(null));
    this.setState({ markers: [] });

    //console.log(this.state.destinations);

    for (let i = 0; i < this.FILTERED_DATA.length; i++) {
      let place = this.FILTERED_DATA[i];
      place.index = i;

      const color = this.state.destinations.includes(place.id) ? "red" : "blue";

      const Marker = {
        url: require("./img/icon-" + color + "-location-pin.png"),
        scaledSize: new google.maps.Size(50, 60),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(50 / 2, 60 / 2 + 15),
        zIndex: 0
      };

      let marker;

      switch (place.placeId) {
        case "parking":
          const parkingMarker = {
            url: require("./img/icon-parking.png"),
            scaledSize: new google.maps.Size(95, 60),
            anchor: new google.maps.Point(95 / 4, 60 / 2 + 20)
          };

          const pMarker = new this.mapAPI.Marker({
            position: {
              lat: place.location.lat,
              lng: place.location.lon,
            },
            map: this.map,
            title: "parking",
            icon: parkingMarker
          });

          marker = pMarker;
          const m2 = {
            marker: pMarker,
            destination: place,
            isDefault: false
          };
          this.state.markers.push(m2);
          break;

        case "trolly":
          const trolleyMarker = {
            url: require("./img/trolly-stop.png"),
            scaledSize: new google.maps.Size(20, 20),
            anchor: new google.maps.Point(20 / 4, 20 / 2),
          };

          const tMarker = new this.mapAPI.Marker({
            position: {
              lat: place.location.lat,
              lng: place.location.lon,
            },
            map: this.map,
            title: "parking",
            icon: trolleyMarker
          });
          marker = tMarker;
          const m3 = {
            marker: tMarker,
            destination: place,
            isDefault: false
          };
          this.state.markers.push(m3);
          break;

        default:
          const _marker = new this.mapAPI.Marker({
            position: {
              lat: place.location.lat,
              lng: place.location.lon,
            },
            map: this.map,
            title: place.name,
            icon: Marker,
            zIndex: 0,
          });
          const m1 = {
            marker: _marker,
            destination: place,
            isDefault: true
          };
          this.state.markers.push(m1);

          marker = _marker;
          break;
      }

      marker.setMap(this.map);
      this.addPopup(
        marker,
        place,
        place.index,
        '<div class="content" id="content-' +
          place.index +
          '">' +
          '<img class="picture" src="' +
          place.thumbnail +
          '" style="object-fit:cover"></img>' +
          '<div class="text">' +
          place.name +
          "</div>" +
        "</div>"
      );
    }
  };

  /** Add markers for terminal 2, 3, and 6 */
  addTerminals = () => {
    var client = this.client;
    async function createEmbedPopupParking(
      user,
      marker,
      parking,
      addPopup,
      apiKey
    ) {
      if (user) {
        user.getCurrentLocation((location) => {
          var imageURL = "";

          client.getAsset("74wWMuaGpa2yqO53M00IGJ").then((e) => {
            imageURL = e.fields.file.url;

            addPopup(
              marker,
              parking,
              1,

              '<div class="parking" id="content-1">' +
                '<img class="picture" src="' +
                imageURL +
                '" style="object-fit:cover"/>' +
                '<div class="text">' +
                "Purchase Cruise Parking" +
                "</div>" +
                +"</div>"
            );
          });
        });
      }
    }

    async function createEmbedPopup(user, marker, terminal, addPopup, apiKey) {
      if (user) {
        // user.getCurrentLocation((location) => {
        client.getAsset("37OlxtbEbAKEaaoYBQDv2Z").then((e) => {
          const imageURL = e.fields.file.url;
          const name = terminal.fields.title
            ? terminal.fields.title
            : terminal.fields.name;
          const description = terminal.fields.description
            ? terminal.fields.description.content[0].content[0].value
            : "Terminal 2";
          const hours = terminal.fields.hours
            ? terminal.fields.hours.content[0].content[0].value
            : "24 Hours - 7 days a week";
          const address = terminal.fields.address
            ? terminal.fields.address.content[0].content[0].value
            : "651 Channelside Dr, Tampa, FL 33602";
          const phoneNumber = terminal.fields.phoneNumber
            ? terminal.fields.phoneNumber
            : "(813) 905-5205";
          const website = terminal.fields.website
            ? terminal.fields.website
            : "";
          const thumbnail = imageURL ? imageURL : "";
          const location = terminal.fields.location;
          const placeId = "parking";

          var images = [imageURL];

          var terminalInfo = {
            name: name,
            description: description,
            hours: hours,
            address: address,
            phoneNumber: phoneNumber,
            website: website,
            images: images ? images : [""],
            thumbnail: thumbnail,
            location: location, // Required
            placeId: placeId, // Required
          };
          // add popup with destination data for location details
          addPopup(
            marker,
            terminalInfo,
            terminal.fields.terminalNumber,

            '<div class="terminal" id="content-' +
              terminal.fields.terminalNumber +
              '">' +
              '<img class="picture" src="' +
              images[0] +
              '" style="object-fit:cover"/>' +
              '<div class="text">' +
              terminal.fields.name +
              "</div>" +
              +"</div>"
          );
        });

        // });
      }
    }
    var mIndex = google.maps.Marker.MAX_ZINDEX;

    // Instantialize terminal marker
    const terminalMarker = {
      url: require("./img/icon-start.png"),
      scaledSize: new google.maps.Size(80, 55),
      // origin:
      anchor: new google.maps.Point(80 / 4, 55 / 2 + 15),
      zIndex: mIndex,
    };

    // Create terminal marker
    if (this.DATA.timer.cruise != "none") {
      this.TERMINALS.map((terminal) => {
        if (terminal.fields.terminalNumber == this.DATA.timer.terminal) {
          terminalMarker.url = require("./img/icon-start-" +
            terminal.fields.terminalNumber +
            ".png");
          terminalMarker.scaledSize = new google.maps.Size(90, 60);
          terminalMarker.anchor = new google.maps.Point(90 / 4, 60 / 2 + 15);

          this.SELECTED_ROUTE.coordinates.unshift(
            ...(this.DATA.tour == "ybor"
              ? terminal.fields.route.ybor
              : terminal.fields.route.riverwalk)
          );

          const marker = new this.mapAPI.Marker({
            position: {
              lat: terminal.fields.location.lat,
              lng: terminal.fields.location.lon,
            },
            map: this.map,
            title: "terminal-" + terminal.fields.terminalNumber,
            icon: terminalMarker,
            zIndex: mIndex,
          });

          marker.setMap(this.map);
          createEmbedPopup(
            this.user,
            marker,
            terminal,
            this.addPopup,
            this.API_KEY
          );
        }
      });
    } else {
      let terminal = this.TERMINALS[1];

      this.SELECTED_ROUTE.coordinates.unshift(
        ...(this.DATA.tour == "ybor"
          ? terminal.fields.route.ybor
          : terminal.fields.route.riverwalk)
      );

      //console.log(terminal);
      const marker = new this.mapAPI.Marker({
        position: {
          lat: terminal.fields.location.lat,
          lng: terminal.fields.location.lon,
        },
        map: this.map,
        title: "terminal-" + terminal.fields.terminalNumber,
        icon: terminalMarker,
      });

      marker.setMap(this.map);
      createEmbedPopup(
        this.user,
        marker,
        terminal,
        this.addPopup,
        this.API_KEY
      );
    }
  };

  /** add popup/callout for marker */
  addPopup = (marker, location, id, content) => {
    const popup = new this.mapAPI.InfoWindow({
      content: content,
    });

    let openDetails = () => this._drawer.onOpenDetails(location);

    google.maps.event.addListener(popup, "domready", function () {
      let contentDiv = document.getElementById("content-" + id);

      if (contentDiv.getAttribute("addedListener") != "true") {
        contentDiv.setAttribute("addedListener", "true");

        contentDiv.addEventListener("mousedown", function (e) {
          openDetails();

          setTimeout(() => {
            popup.close();
          }, 500);
        });
      }
    });

    marker.addListener("click", () => {
      if (this.popups.length > 0) {
        this.popups[0].close();
        this.popups.splice(0, 1);
      }

      popup.open({
        anchor: marker,
        map: this.map,
        shouldFocus: true,
      });

      this.popups.push(popup);

      // try {
      //   document.getElementsByClassName("gm-style-iw-c")[0].style.border = "3px solid #005e91";
      // } catch(e){console.error(e)}
    });

    this.map.addListener("click", () => {
      popup.close();
    });
  };

  setPlace = async (place) => {
    await this.setState({ selectedDestination: place });
  };

  onReCenter = () => {
    this.map.panTo({
      lng: this.CameraLocation.longitude,
      lat: this.CameraLocation.latitude,
    });
    this.map.setZoom(this.CameraLocation.zoom);
  };

  /** Execute functions when the google maps api is loaded */
  handleApiLoaded(map, maps) {
    this.map = map;
    this.mapAPI = maps;
    this.placeData = new PlaceData(map, maps);
    this.user = new User(map, maps);

    if (this.DATA.contentful.destinations.length > 0) {
      this.addMarkers();
      // //console.log(this.DATA.timer.terminal)
      this.addTerminals();
    }

    this.renderRoute();
    this.renderSelectedRoute();
  }

  componentDidMount() {
    if (!this.DATA.contentful.destinations.length > 0) {
      //console.log("redirect");
      return <Navigate to="/" />;
    }
  }

  render() {
    this.filterData();

    var time = this.DATA.customTimer;

    var options = {
      fullscreenControl: false,
      zoomControl: false,
      mapId: "7b34999265fd6291",
      clickableIcons: false
    };

    return (
      <View style={styles.container}>
        <View
          style={{
            // flex: 0,
            height: "100%",
            width: "100%",
            alignSelf: "center",
            overflow: "hidden",
            maxWidth: 460,
          }}
        >
          {/* Google map */}
          <View style={styles.map}>
            <GoogleMap
              bootstrapURLKeys={{
                key: this.API_KEY,
                libraries: ["places"],
              }}
              defaultCenter={{
                lat: this.CameraLocation.latitude,
                lng: this.CameraLocation.longitude,
              }}
              defaultZoom={this.CameraLocation.zoom}
              onGoogleApiLoaded={
                ({ map, maps }) => this.handleApiLoaded(map, maps)
                // //console.log('tyest')
              }
              options={options}
              ref={(ref) => (this._map = ref)}
              yesIWantToUseGoogleMapApiInternals
            ></GoogleMap>
          </View>

          {/* Timers */}
          <View style={styles.timerContainer}>
            {/* Current Tour */}
            <NewLink params="cruiseline" to="/" style={{ textDecoration: "none", margin: 15 }}>
              <TouchableOpacity style={styles.currentTime}>
                {/* <FontAwesome5 name="home" size={18} color="#00437d" /> */}
                <Image
                  source={require("./img/logo-ptb.png")}
                  style={{ width: 25, height: 25 }}
                ></Image>
                <View style={{ alignItems: "flex-start", marginLeft: 5 }}>
                  <Text
                    style={{
                      fontWeight: "900",
                      fontSize: 18,
                      color: "#00437d",
                    }}
                  >
                    {this.DATA.tour == "tampa"
                      ? "Tampa Riverwalk"
                      : "Ybor City"}
                  </Text>
                </View>
              </TouchableOpacity>
            </NewLink>

            {/* Time remaining timer */}
            <NewLink params="cruiseline" to="/" style={{ textDecoration: "none", margin: 15 }}>
              <TouchableOpacity style={styles.remainingTime}>
                {/* <Ionicons name="chevron-forward-outline" size={30} color="#ba0000" /> */}
                <View style={{ alignItems: "flex-start" }}>
                  <Text
                    style={{
                      fontStyle: "italic",
                      fontSize: 14,
                      color: "#ba0000",
                    }}
                  >
                    {" "}
                    Time Remaining{" "}
                  </Text>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 20,
                      color: "#ba0000",
                    }}
                  >
                    {time ? (
                      <Time type={"timeRemaining"} />
                    ) : (
                      this.DATA.boarding.time_remaining
                    )}
                    <MaterialCommunityIcons
                      name="clock"
                      size={18}
                      color="#ba0000"
                      style={{ marginLeft: 5 }}
                    />
                  </Text>
                </View>
              </TouchableOpacity>
            </NewLink>
            {/* </LinearGradient> */}
          </View>

          <View
            style={{ position: "absolute", width: "95%", top: 70, left: 10 }}
          >
            <TouchableOpacity
              style={styles.tutorial}
              onPress={() => {
                this.setState({ tutorialOpened: true });
              }}
            >
              <Text style={styles.resetMapText}> Tutorial </Text>
            </TouchableOpacity>
          </View>

          {/* Re-center button / centers the map camera */}
          <View
            style={{
              flex: 0,
              position: "sticky",
              top: "50%",
              width: "95%",
              left: 10,
              paddingBottom: 35,
            }}
          >
            <TouchableOpacity style={styles.resetMap} onPress={this.onReCenter}>
              <Text style={styles.resetMapText}> Re-center </Text>
            </TouchableOpacity>
          </View>

          {/* The container that holds all the filters and cards */}
          <Drawer
            selectedDestination={this.state.selectedDestination}
            selectedFilter={this.state.filter}
            selectedDestinations={this.state.destinations}
            onFilterSelect={this.onFilterSelect}
            onAddDestination={this.addDestination}
            onRemoveDestination={this.removeDestination}
            onOpenDetails={this.openDetails}
            filteredData={this.FILTERED_DATA}
            ref={(ref) => (this._drawer = ref)}
          />

          {this.state.tutorialOpened ? (
            <View
              style={{ position: "absolute", width: "100%", height: "100%" }}
            >
              <Tutorial
                onClose={() => {
                  this.setState({ tutorialOpened: false });
                }}
                slides={
                  this.DATA.tour != "ybor" ? 
                  [
                    {
                        id: 1,
                        url: require("./components/tutorial/tutorial_slides/riverwalk/Slide-01.jpg")
                    },
                    {
                        id: 2,
                        url: require("./components/tutorial/tutorial_slides/riverwalk/Slide-02.jpg")
                    },
                    {
                        id: 3,
                        url: require("./components/tutorial/tutorial_slides/riverwalk/Slide-03.jpg")
                    },
                    {
                        id: 4,
                        url: require("./components/tutorial/tutorial_slides/riverwalk/Slide-04.jpg")
                    },
                    {
                        id: 5,
                        url: require("./components/tutorial/tutorial_slides/riverwalk/Slide-05.jpg")
                    },
                    {
                        id: 6,
                        url: require("./components/tutorial/tutorial_slides/riverwalk/Slide-06.jpg")
                    }
                ] : [
                  {
                      id: 1,
                      url: require("./components/tutorial/tutorial_slides/ybor/ybor-slide-01.png")
                  },
                  {
                      id: 2,
                      url: require("./components/tutorial/tutorial_slides/ybor/ybor-slide-02.png")
                  },
                  {
                      id: 3,
                      url: require("./components/tutorial/tutorial_slides/ybor/ybor-slide-03.png")
                  },
                  {
                      id: 4,
                      url: require("./components/tutorial/tutorial_slides/ybor/ybor-slide-04.png")
                  },
                  {
                      id: 5,
                      url: require("./components/tutorial/tutorial_slides/ybor/ybor-slide-05.png")
                  },
                  {
                      id: 6,
                      url: require("./components/tutorial/tutorial_slides/ybor/ybor-slide-06.png")
                  },
                  {
                      id: 7,
                      url: require("./components/tutorial/tutorial_slides/ybor/ybor-slide-07.png")
                  }
              ]
                }
              />
            </View>
          ) : (
            <View />
          )}
        </View>
      </View>
    );
  }
}
