
import {
    StyleSheet
  } from 'react-native';
  
  export default StyleSheet.create({
    container: {
        alignItems: "center",
        width: "100%",
        height: "100%",
        flex: 1
    },

    picture: {
        height: "100%",
        width: "100%",
        borderRadius: 15
    },

    titleContainer: {
        top: 0,
        alignSelf: "flex-start",
    },

    button: {
        backgroundColor: "rgba(48, 124, 239, 1)",
        borderRadius: 20,
        flexDirection: "row",
        width: 200,
        height: 40,
        margin: 10,
        marginLeft: 10,
        alignItems: "center",
        justifyContent: "center"
    },
    
    addRoute: {
        display: "flex",
        padding: 5,
        paddingLeft: 15,
        paddingRight: 15,
        alignItems: "center",
        flexDirection: "row",
        fontWeight: "bold",
        fontSize: 15, 
        color: "white"
    },

    shadow: {
        shadowColor: "black",
        shadowRadius: 2.3,
        shadowOpacity: 1,
        shadowOffset: { width: 0, height: 1.4},
    },

    separator: {
        width: "95%",
        height: 1,
        backgroundColor: "lightgray",
        marginTop: 15,
        marginBottom: 15
        
    },

    description: {
        margin: 5,
        marginLeft: 10,
        color: "#00437d",
        fontWeight: "500"

    },

    title: {
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 25,
        fontWeight: "bold",
        color: "#00437d"
    },

    pictures: {
        //alignSelf: "center",
        position: "absolute",
        //flex: 1,
        
        width: "100%",
        height: 150

    },

    card: {
        width: 200, 
        height: 130,
        margin: 10,
        borderRadius: 15,

        backgroundColor: "lightgray"
    }
  })