
export default class Directions {
    constructor(map, maps){

        this.mapAPI = maps;
        this.map = map;

        this.directionsRenderer = new google.maps.DirectionsRenderer();
        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer.setMap(this.map);

    }

    async snapToPath(destination) {
        await this.createRoute(destination, destination, (response) => {
            return response.routes[0].legs[0].start_location;
        });
    }

    async setDirections(origin, destination) {
        var directionsRenderer = this.directionsRenderer;

        this.createRoute(origin, destination, (response) => {
            directionsRenderer.setDirections(response);
        });
    }

    async createRoute(origin, destination, callback) {
        var directionsService = this.directionsService;

        directionsService.route({
            origin: {
                query: origin
            },
            destination: {
                query: destination
            },
            travelMode: google.maps.TravelMode.WALKING
        }).then((response) => {
            callback(response);
        }).catch((e) => window.alert("Directions request failed due to " + e));
    }


}