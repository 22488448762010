import { transform } from "@babel/core";
import { StyleSheet } from "react-native";

const WIDTH = document.body.scrollWidth;


export default StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },

  timerContainer: {
    // backgroundColor: "rgba(41, 100, 147, 0.9)",
    width: "100%",
    height: "100%",
    justifyContent: "center"
  },

  buttonsContainer: {
    top: 0,
    flexDirection: "row",
  },

  cardContainer: {
    transform: [{scale: document.body.scrollWidth / 450 < 1 ? document.body.scrollWidth / 450 : 1}],
    width: 352,
    backgroundColor: "white",
    alignSelf: "center",
    borderRadius: 10,
    marginBottom: 30,
    overflow: "hidden",
    padding: 20,
    alignContent: "space-between",
    justifyContent: "center",
  },

  clockContainer: {
    alignItems: "center"
  },

  set: {
    flex: 1,
    minWidth: "70%",
    height: 50,
    marginRight: 10,

    alignSelf: "center",

    borderRadius: 5,
    backgroundColor: "rgba(204, 0, 44, 1)",
    justifyContent: "center",
  },

  reset: {
    flex: 1,
    maxWidth: "100%",
    height: 50,

    alignSelf: "center",

    borderRadius: 5,
    backgroundColor: "rgba(41, 100, 147, 0.9)",
    justifyContent: "center",
  },

  resetText: {
    alignSelf: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "100%",
  },

  setText: {
    alignSelf: "center",

    color: "white",
    fontWeight: "bold",
    fontSize: "150%",
  },

  centerText: {
    flex: 1,
    alignSelf: "center",

    color: "white",
    fontWeight: "bold",
    fontSize: 25,
  },
});
