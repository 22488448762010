import Map from "./pages/Map/Map";

import Front from "./pages/Front/Front";
import FrontCarnival from "./pages/Front/FrontCarnival";
import FrontNorwegian from "./pages/Front/FrontNorwegian";
import FrontCelebrity from "./pages/Front/FrontCelebrity";
import FrontCaribbean from "./pages/Front/FrontCaribbean";

import * as serviceWorker from './serviceWorkerRegistration';
import { useUrlParams} from './UrlHandler';

import * as React from "react";
import { BrowserRouter, Route, Link, Routes, useParams, useLocation } from "react-router-dom";
import { registerRootComponent } from 'expo';

import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';

const trackingId = "G-YWFZZW0SE7";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();


// history.listen(location => {
//   ReactGA.set({page: location.pathname }); // Update the user's current page
//   //ReactGA.pageview(location.pathname); // Record a pageview for the given page
//   ReactGA.send({ hitType: "pageview", page: location.pathname });
// });


function App() {
  return (
    <div>
      <BrowserRouter>
        
          <Routes>
            <Route path="/" element={<FrontSwitch/>} />
            
            <Route path="tour" element={<Map/>} />
            
          </Routes>
        
      </BrowserRouter>

    </div>
  );
}


function FrontSwitch() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let query = useUrlParams();
  let id = query.get("cruiseline");
  let cruiseline = "";

  let callEvent = (event) => {
    ReactGA.event({
      category: "cruiseline",
      action: "user visted with " + event + " cruiseline"
    });
  }

  //console.log(id);
  switch(id) {
    default:
      cruiseline="no";
      callEvent(cruiseline);
      return <Front/>;
    case "carnival":
      cruiseline="carnival";
      callEvent(cruiseline);
      return <FrontCarnival/>;
    case "royal-caribbean":
      cruiseline="royal caribbean";
      callEvent(cruiseline);
      return <FrontCaribbean/>;
    case "norwegian":
      cruiseline="norwegian";
      callEvent(cruiseline);
      return <FrontNorwegian/>;
    case "celebrity":
      cruiseline="celebrity";
      callEvent(cruiseline);
      return <FrontCelebrity/>;
  }

  return <PageComponent/>;
  
}

export default registerRootComponent(App);

serviceWorker.register();
