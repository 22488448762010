
import {
    StyleSheet
  } from 'react-native';
  
  export default StyleSheet.create({
      container: {
        ...StyleSheet.absoluteFillObject,
        // backgroundColor: "rgba(13, 84, 138, .9)",
      },

      blueText: {
        color: "#00437d",
        fontStyle: "italic"
      },

      whiteText: {
        color: "white",
        fontStyle: "italic"
      },

      departureContainer: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
      },

      customTimer: {
        margin: 5,
        width: "85%",
        height: 110,
        alignSelf: "center",

        backgroundColor: "white",
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
        
      },

      imageContainer: {
        position: "absolute",
        right: 5,
        width: 160, 
        height: 100, 
        borderRadius: 5,
        backgroundColor: "lightgray",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
      },

      messageText: {
        fontSize: 15,
        textAlign: "center",

        fontWeight: "bold",
        color: "#00437d",
        marginLeft: 5
      },

      cardText: {
        fontSize: 23,

        fontWeight: "bold",
        color: "#00437d",
        marginLeft: 5
      },

      cardList: {
        // margin: 0,
        // top: 40,
        width: "100%",
        alignSelf: "center"
      },

      card: {
        margin: 6,

        width: "85%",
        height: 85, //370
        padding: 10,
        // flexDirection: "row",

        backgroundColor: "white",
        borderRadius: 10,
        // alignItems: "center",
        alignSelf: "center",
        justifyContent: "center",
        //justifyContent: "center",

        shadowColor: 'black',
        shadowOpacity: 0.5,
        shadowRadius: 3
        
      },

      date: {
        fontSize: 20, 
        fontWeight: "500",
        width: "85%",
        padding: 15,
        backgroundColor: "rgba(255, 255, 255, .2)",//rgba(18, 57, 108, 1)",
        // textAlign: "center",
        alignSelf: "center",
        
        flexDirection: "row",
        justifyContent: "space-between",
        // alignItems: "center",

        borderRadius: 10,
        marginBottom: 5,
        marginTop: 5
      },

      centerText: {
        // flex: 1, 
        //position: "absolute", 
        // textAlign: "center",
        // top: 10,
        color: "white",
        fontWeight: "bold", 
        fontSize: 25,
      },

      upperbar: {
        backgroundColor: "rgba(255, 255, 255, 0.2)", 
        alignSelf: "center",
        height: 2,
        width: "90%",
        top: 20, 
      },

      departureTimers: {
          alignItems: "center"
      }
      
    });