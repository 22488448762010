import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Animated,
} from "react-native";

import PropTypes from "prop-types";
import Details from "./DestinationDetails";
import DestinationFilters from "./DestinationFilters";
import DestinationList from "./DestinationList";

import { useSpring, a, config, SpringValue } from "react-spring";
import { useDrag } from "react-use-gesture";

const height = 115; //245

export default class Drawer extends Component {
  constructor(props) {
    super(props);

    this.FILTERED_DATA = this.props.filteredData;
    this.state = {
      destinations: this.props.selectedDestinations, // list of selected destinations/routes

      // Height of destination selector block
      ySize: new SpringValue(115), //240
      destinationsOpacity: new Animated.Value(1),
      detailsOpacity: new Animated.Value(0),
      hideDetails: true,
      hideSelector: false,
      hideCards: false,
      drawerOpened: false,
      pictures: [],
      selectedDestination: this.props.selectedDestination,
      horizontalList: true,
      opened: false,

      // Initial filter
      filter: this.props.selectedFilter,
    };
  }

  static propTypes = {
    selectedDestination: PropTypes.object,
    selectedFilter: PropTypes.string.isRequired,
    selectedDestinations: PropTypes.array.isRequired,
    onFilterSelect: PropTypes.func.isRequired,
    onAddDestination: PropTypes.func.isRequired,
    onRemoveDestination: PropTypes.func.isRequired,
    filteredData: PropTypes.array.isRequired,
    onOpenDetails: PropTypes.func.isRequired,
  };

  AnimatedDetails = (props) => {
    let isSelected = this.props.selectedDestinations.includes(
      this.props.selectedDestination.id
    );
    // //console.log(this.props.selectedDestination)
    return (
      <Animated.View
        style={{
          opacity: this.state.detailsOpacity,
          position: "absolute",
          overflow: "hidden",
          left: 0,
          rigth: 0,
          width: "100%",
          height: "100%"
        }}
      >
        <Details
          selectedDestination={this.props.selectedDestination}
          onRouteAdd={() => {
            this.props.onAddDestination();
            this.onCloseDetails();
          }}
          onRouteRemove={() => {
            this.props.onRemoveDestination();
            this.onCloseDetails();
          }}
          selected={isSelected}
          ref={(ref) => (this._destinationDetails = ref)}
          y={props.y}
        />
      </Animated.View>
    );
  };

  AnimatedSelector = (props) => {
    return (
      <Animated.View
        style={{
          // position: "absolute",
          opacity: this.state.destinationsOpacity,
          // overflow: "scroll",
          height: "100%",
          width: "100%",
        }}
      >
        <DestinationFilters
          ref={(ref) => (this._destinationFilters = ref)}
          filteredData={this.FILTERED_DATA}
          onCardPressed={(item) => this.props.onOpenDetails(item)}
          onFilterSelect={(filter) => {
            this.props.onFilterSelect(filter);
            // this.setState({filter: filter.type});

            if (this._destinationList._carousel) {
              if (this._destinationList._carousel.props.data.length > 0)
                this._destinationList._carousel.scrollToIndex({ index: 0 });
              // this._destinationSelector.setState({ horizontal: true });
              // this._destinationSelector.state.horizontal = true;
            }
          }}
          renderItems={this.renderCarouselItem}
          destinations={this.props.selectedDestinations}
          hideCards={this.state.hideCards}
          selectedFilter={this.props.selectedFilter}
        />

        <DestinationList
          filteredData={this.props.filteredData}
          onCardPressed={(item) => {
            this.setState({ hideSelector: true });
            this.props.onOpenDetails(item);
          }}
          renderItems={this.renderCarouselItem}
          selectedDestinations={this.props.selectedDestinations}
          onAddDestination={this.props.onAddDestination}
          onRemoveDestination={this.props.onRemoveDestination}
          onOpenDetails={this.onOpenDetails}
          hideCards={this.state.hideCards}
          opened={this.state.opened}
          ref={(ref) => (this._destinationList = ref)}
        />
      </Animated.View>
    );
  };

  /** Chooses which component in the drawer to render */
  DrawerController = (props) => {
    return (
      <View style={{ width: "100%", height: "100%" }}>
        {!props.hideSelector ? <props.selector y={props.y} /> : <View />}
        {!props.hideDetails ? <props.details y={props.y} /> : <View />}
      </View>
    );
  };

  onOpenDetails = (item) => {
    this.props.onOpenDetails(item);
    this.openDestinationDetails();

    this.open({ canceled: false, openDetails: true });
  };

  onCloseDetails = () => {
    this.closeDestinationDetails();
    //console.log("close");
    this.close(0, false);
  };

  Drawer = (props) => {

    const [{ y }, set] = useSpring(() => ({ y: height }));

    const open = async ({ canceled = false, openDetails = false }) => {
      // this._destinationList._carousel.scrollToIndex({index: 0});
      set({
        y: document.body.scrollHeight * 0.93,
        immediate: false,
        config: canceled ? config.wobbly : config.stiff,
      });
      openDetails || (!this.state.hideDetails && this.state.hideCards) ? props.onOpenDetails() : props.onOpenList();
    };
    const close = async (velocity = 0, hide = false) => {
      // this._destinationList._carousel.scrollToIndex({index: 0});
      set({
        y: hide ? 245 : height,
        immediate: false,
        config: { ...config.stiff, velocity },
      });
      props.onClose();
    };

    this.open = open;
    this.close = close;

    const bind = useDrag(
      ({
        event,
        last,
        vxvy: [, vy],
        down,
        movement: [, my],
        cancel,
        canceled,
        xy: [, y],
      }) => {
        event.preventDefault();

        if (my < -70) cancel();

        let newY = document.body.scrollHeight - y + 15;
        if (last) {
          vy > 0.5
            ? y > height * 2
              ? close(vy, true)
              : close(vy, false)
            : y < height * 2
            ? open({ canceled })
            : y > height * 4 ? close(vy, false) : close(vy, true);
        } else set({ y: newY, immediate: false });
      },
      {
      initial: () => [0, y.get()],
        filterTaps: true,
        bounds: { top: 0 },
        rubberband: true,
      }
    );

    return (
      <a.div
        style={{
          height: y,
          width: "100%",
          position: "relative",
          backgroundColor: "white",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          boxShadow: "0px -1px 10px #9E9E9E",
          // flex: 0
        }}
      >
        <a.div {...bind()} style={{ touchAction: "pan-x" }}>
          <TouchableOpacity
            activeOpacity={1}
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              top: 0,
              height: 30,
              justifyContent: "center",
            }}
            onPress={() => (!this.state.opened ? open(true) : close(0, false))}
          >
            <View
              style={{
                alignSelf: "center",
                top: 0,
                backgroundColor: "#d1d1d1",
                width: 70,
                height: 5,
                borderRadius: 20,
              }}
            />
          </TouchableOpacity>
        </a.div>

        <props.DrawerController
          y={y}
          details={props.AnimatedDetails}
          selector={props.AnimatedSelector}
          hideDetails={this.state.hideDetails}
          hideSelector={this.state.hideSelector}
        />

      </a.div>
    );
  };

  render() {
    return (
      <this.Drawer
        AnimatedDetails={this.AnimatedDetails}
        AnimatedSelector={this.AnimatedSelector}
        DrawerController={this.DrawerController}
        onOpenDetails={this.openDestinationDetails}
        onOpenList={this.openList}
        onClose={this.closeDestinationDetails}
        opened={this.state.opened}
        hideSelector={this.state.hideSelector}
        // hideCards={this.state.hideCards}
      />
    );
  }

  // ANIMATED FUNCTIONS

  openList = async () => {
    this.setState({ hideCards: true, opened: true, hideDetails: true });
  };

  closeList = async () => {
    this.setState({ hideCards: false, opened: false, hideDetails: true });
  };

  openDestinationDetails = async () => {
    // this.open();

    Animated.timing(this.state.destinationsOpacity, {
      toValue: 0,
      duration: 400,
      useNativeDriver: false,
    }).start();

    setTimeout(() => {
      //this.setState({showDetails: true})
      Animated.timing(this.state.detailsOpacity, {
        toValue: 1,
        duration: 400,
        useNativeDriver: false,
      }).start();
    }, 300);

    this.setState({ hideDetails: false, hideCards: true });
    setTimeout(() => {
      this.setState({ hideSelector: true, opened: true });
    }, 600);
  };

  closeDestinationDetails = async () => {
    this.setState({ hideSelector: false, hideCards: false, opened: false });
    setTimeout(() => {
      this.setState({ hideDetails: true });
    }, 600);

    Animated.timing(this.state.detailsOpacity, {
      toValue: 0,
      duration: 400,
      useNativeDriver: false,
    }).start();

    setTimeout(() => {
      //this.setState({showDetails: false})

      Animated.timing(this.state.destinationsOpacity, {
        toValue: 1,
        duration: 400,
        useNativeDriver: false,
      }).start();
    }, 300);
  };
}
