import Directions from './Directions';
export default class User {
    constructor(map, maps){

        this.mapAPI = maps;
        this.map = map;

        this.userLocation = {}
        this.directions = new Directions(this.map, this.mapAPI);

        this.init();
    }

    getOrientation(e) {
      var absolute = e.absolute;
      var alpha = e.alpha;
      var beta = e.beta;
      var gamma = e.gamma;

      if (document.querySelector('#markerLayer img')) {

        let absoluteHeading = 0;
        
        if (e.webkitCompassHeading) {
          absoluteHeading = e.webkitCompassHeading;
        } else {
          absoluteHeading = e.alpha;
        }
        
        document.querySelector('#markerLayer img').style.transform = 'rotate(' + absoluteHeading + 'deg)';
        document.querySelector('#markerLayer img').style.transformOrigin = '50% 76%';
        document.querySelector('#markerLayer img').parentElement.style.overflow = 'visible';
        
      }
    }

    getCurrentLocation(callback) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => getPosition_success(position), 
          (error) => getPosition_error(error)
        );
      } else {
        alert("Uh Oh! We cannot track your location on this browser!");
      }
      
      function getPosition_success(position) {
        let userLocation = {
          lat: parseFloat(position.coords.latitude),
          lng: parseFloat(position.coords.longitude),
        }

        callback(userLocation);
      }
  
      function getPosition_error(error) {
        console.error("Error Code: " + error.code);

        callback(error.code);
      }
    }

    updateLocation(callback, time) {
      this.interval = setInterval(() => {
        this.getCurrentLocation((location) => {
            callback(location)
        });
      }, time);
    }

    startLocationTracking(item) {
      this.updateLocation((location) => {
        ////console.log("position set to: " + JSON.stringify(location));
        if (typeof(location) == typeof(0))
          clearInterval(this.interval);
        else {  
          let latlng = new google.maps.LatLng(location.lat, location.lng);
          this.userLocation = location;
          item.setPosition(location);
        }
      }, 100);
    }

      /** Track and visualize user location to the map */
    init() {

        var image = require('../img/icon-user-location.svg');
        let userLocation = {lat: 0, lng: 0};
        
        const userPin = new this.mapAPI.Marker({
          position: userLocation, //{ lat: userLocation.lat, lng: userLocation.lng }
          map: this.map, 
          title: "user",
          icon: {
            url: image,
            scale: 2,
            origin: new google.maps.Point(0,0)
          }
        });

        userPin.setMap(this.map);

        var overlay = new this.mapAPI.OverlayView();
        overlay.draw = function() {
            this.getPanes().markerLayer.id = 'markerLayer';
        }

        overlay.setMap(this.map);

        let device = navigator.userAgent;
        
        // if ( /iPhone|iPad|iPod|Macintosh|Mac/i.test(navigator.userAgent) ) {
        //   window.addEventListener('deviceorientation', this.getOrientation, true);
        // } else {
        //   window.addEventListener("deviceorientationabsolute", this.getOrientation, true);
        // }

        // window.addEventListener('deviceorientation', this.getOrientation, true);
        
        this.getCurrentLocation((location) => {
          userPin.setPosition(location);
          this.startLocationTracking(userPin);
        });
        
    }
}