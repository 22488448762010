import React, { Component } from "react";
import { View, Text, FlatList, TouchableOpacity, Image, Linking } from "react-native";

import PropTypes from "prop-types";

import { AntDesign } from "@expo/vector-icons";
import styles from "./DetailsStyles";

export default class Details extends Component {
  constructor(props) {
    super(props);

    this.RIVERWALK = require("../../../../data/routes/riverwalk.json");
    this.YBOR = require("../../../../data/routes/ybor.json");

    this.DATA = require("../../../data.json");

    this.state = {
      place: this.props.selectedDestination,
      selected: this.props.selected,
    };
  }

  static propTypes = {
    selectedDestination: PropTypes.object,
    onRouteAdd: PropTypes.func,
    onRouteRemove: PropTypes.func,
    distances: PropTypes.object,
    selected: PropTypes.bool,
    y: PropTypes.any,
  };

  renderPictures = ({ item }) => {
    return (
      <View style={styles.card}>
        <Image source={{ uri: item }} style={styles.picture} />
      </View>
    );
  };

  remove = () => {
    this.props.onRouteRemove();
  };

  add = () => {
    this.props.onRouteAdd();
  };

  // Button component to add destination to tour
  addToMyTour = () => {
    let isSelected = this.props.selected;
    return (
      <View>
        {isSelected ? (
          <View style={[styles.button, { backgroundColor: "rgba(204, 0, 44, 1)", width: 260 }]}>
            <TouchableOpacity onPress={() => this.remove()}>
              <Text
                style={styles.addRoute}
              >
                <AntDesign
                  name="plus"
                  size={24}
                  color="white"
                  style={{ marginRight: 10, transform: "rotate(45deg)" }}
                />
                REMOVE FROM MY TOUR
              </Text>
            </TouchableOpacity>
          </View>
        ) : (
          <View style={styles.button}>
            <TouchableOpacity onPress={() => this.add()}>
              <Text style={styles.addRoute}>
                <AntDesign
                  name="plus"
                  size={24}
                  color="white"
                  style={{ marginRight: 10 }}
                />
                ADD TO MY TOUR
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  };

  // Button component to redirect to PTB site
  purchaseTickets = () => {
    return (
      <a style={{textDecoration: "none"}} href="https://www.porttb.com/parking">
        <View style={styles.button}>
          <TouchableOpacity onPress={() => {}}>
            <Text style={styles.addRoute}>
              PURCHASE TICKETS
            </Text>
          </TouchableOpacity>
        </View>
      </a>
    );
  };

  render() {
    var place = this.props.selectedDestination;
    // //console.log(place.id)
    return (
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          {/* TITLE */}
          <Text style={[styles.title, { width: 250 }]}>{place.name}</Text>

          {/* ADD/REMOVE FROM TOUR BUTTON */}
          {place.placeId != "parking" ? <this.addToMyTour /> : <this.purchaseTickets/>}
          
        </View>

        <View
          style={{
            height: "100%",
            width: "100%",
            overflow: "scroll",
            flex: 1,
            marginBottom: 20,
          }}
        >
          {/* IMAGES */}
          <View style={{ marginBottom: 170 }}>
            <View style={styles.pictures}>
              <FlatList
                data={place.images}
                horizontal={true}
                renderItem={this.renderPictures}
                keyExtractor={(item) => place.images.indexOf(item)}
                ref={(ref) => (this._pictures = ref)}
              />
            </View>
          </View>

          <Text
            style={[styles.description, { fontWeight: "bold", fontSize: 16 }]}
          >
            DESCRIPTION
          </Text>
          <Text style={styles.description}>{place.description}</Text>

          <View style={styles.separator} />

          <View style={{ alignSelf: "flex-start" }}>
            <Text
              style={[
                styles.title,
                { fontSize: 16, textTransform: "uppercase" },
              ]}
            >
              Trip details
            </Text>

            <Text style={[styles.description, {textDecorationLine: 'underline'}]} onPress={() => Linking.openURL(place.website)}>{place.website}</Text>
            <Text style={styles.description}>{place.phoneNumber}</Text>
            <Text style={styles.description}>{place.address}</Text>
            <Text style={styles.description}>{place.hours}</Text>
            {/* <Text style={[styles.description, {textTransform: "capitalize"}]}>{place.type}</Text> */}
          </View>

          <View style={styles.separator} />
        </View>
      </View>
    );
  }
}
