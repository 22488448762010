import axios from "axios";

export default class RequestData {
  constructor(host, path) {
    this.host = host;
    this.path = path;
  }

  getCachedData(key, onCacheExists, onCacheDoesntExist) {
    const now = new Date();
    var keyPrefix = "cached-";
    var masterKey = keyPrefix + key;

    let cache = JSON.parse(localStorage.getItem(masterKey));

    if (cache) {
      if (now.getTime() < cache.expiry)
        onCacheExists(masterKey, cache.value);
      else 
        onCacheDoesntExist(masterKey);
    } else {
      onCacheDoesntExist(masterKey);
    }
  }

  getData(key, callback) {
    const now = new Date();

    axios.interceptors.response.use(
      (response) => {

        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    let config = {
      method: "get",
      baseURL: this.host,
      url: this.path,
      responseType: "json",
    };

    this.getCachedData(
      key,
      /** onCacheExists */
      (masterKey, cache) => {
        callback(cache);
        //console.log("recieved cache: " + masterKey);
      },
      /** onCacheDoesntExist */
      (masterKey) => {
        axios.request(config).then((response) => {
          let data = response.data;
          let valueWithExpiry = {
            value: data,
            expiry: now.getTime() + 86400000 // 24 hours
          }
          localStorage.setItem(masterKey, JSON.stringify(valueWithExpiry));
          callback(data);
          //console.log("cached: " + masterKey);
        });
      }
    );
  }
}
