
import {
  StyleSheet
} from 'react-native';

export default StyleSheet.create({
    container: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: "#0D548A",
    },
    map: {
      // ...StyleSheet.absoluteFillObject,
      width: "100%",
      height: "100%",
      top: 35,
      position: "relative",
      flex: 1
    },

    tutorial: {
      alignSelf: "flex-end",
      // left: "80%",
      // justifyContent: "flex-end",
      width: 70,
      height: 20,
      
      
      borderRadius: 5,
      backgroundColor: "#ba0000",
      alignItems: "center",
      justifyContent: "center",
    },

    resetMap: {
      alignSelf: "flex-end",
      // left: "80%",
      // justifyContent: "flex-end",
      width: 70,
      height: 20,
      
      
      borderRadius: 5,
      backgroundColor: "#005e91",
      alignItems: "center",
      justifyContent: "center",
      

      // shadowRadius: 3,
      // shadowColor: "gray"
    },

    resetMapText: {
      textAlign: "center",
      fontWeight: "bold",
      color: "white", 
      fontSize: 12
    },
    
    image: {
      flex: 1,
      width: 200,
      height: 200,
      resizeMode: 'contain',
      shadowOffset: {width: 0, height: 5 },
      shadowColor: 'black',
      shadowOpacity: 0.6
    },
    addRoute: {
      backgroundColor: "rgba(255, 255, 255, 1)",
      width: 25,
      height: 25,
      borderRadius: 15,

      justifyContent: "center",
      alignItems: "center"
    },
    pinShadow: {  
      elevation: 10,
      shadowColor: 'gray',
      shadowOffset: { width: 0, height: 0},
      shadowOpacity: 0.6,
      shadowRadius: 3, 
    },

    currentTime: {
      flex: 1,
      flexDirection: "row",
      fontSize: window.screen.width,
      margin: 0,
      fontStyle: "italic",
      fontWeight: "300",
      color: "#005e91",
      alignContent: "center",
      justifyContent: "center"
      // top: -10
    },
    remainingTime: {
      flex: 1,
      flexDirection: "row-reverse",
      fontSize: 17,
      margin: 0,
      fontStyle: "italic",
      color: "darkred",
      // top: -10
    },
    timerContainer: {
      flexDirection: "row",
      position: "absolute", 
      top: 0,
      width: "100%",
      height: 55,
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "white",
      shadowRadius: 5,
      shadowOpacity: .3,
      shadowColor: "black",
    },
    filterText: {
      color: "#a1a1a1",
      fontWeight: "bold",
      alignSelf: "center",
      fontSize: 12
      
    },
    filterContainer: {
      flex: 1,
      justifyContent: "space-evenly"
      // top: 180
    },
    filter: {
      flex: 0,
      backgroundColor: "#a1a1a1",
      borderRadius: 20,
      minWidth: 40,
      minHeight: 40,
      justifyContent: "center",
      alignItems: "center"
    },

  });
  