import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableHighlight,
  Image
} from 'react-native';

import { FontAwesome5 } from '@expo/vector-icons';
import PropTypes from 'prop-types';

import FilterIcon from './FilterIcon'

import styles from './DrawerStyles'

export default class DestinationFilters extends Component {
    constructor(props) {
        super(props)

        this.RIVERWALK = require('../../../../data/routes/riverwalk.json');
        this.YBOR = require('../../../../data/routes/ybor.json');

        this.DATA = require('../../../data.json');
        this.FILTERS = require('../../../../data/filters.json');

        this.state = {
          filter: this.props.selectedFilter,
          // horizontal: true,
          refresh: false
        }
    }

    static propTypes = {
        filters: PropTypes.array,
        filteredData: PropTypes.array,
        onCardPressed: PropTypes.func,
        onFilterSelect: PropTypes.func,
        renderItems: PropTypes.func,
        opacity: PropTypes.number,
        destinations: PropTypes.array,
        hideCards: PropTypes.bool,
        selectedFilter: PropTypes.string,
    }
    
    render() {
      var getColor = (filter) => {
        if (filter == 'my tour' && this.props.destinations.length > 0) {
          return "red";
        }
        else if (this.props.selectedFilter != filter) {
          return "#a1a1a1";
        } else {
          return "#00609d";
        }
      }

        return(
        <View>

          {/* Filter Buttons */}
          <View style={{flexDirection: "row", margin: 5}}>
          { 
            this.FILTERS.map((filter, index) => {

            return (
              <View style={styles.filterContainer} key={index}>
                <TouchableHighlight
                style={{borderRadius: 20, alignSelf: "center"}}
                activeOpacity={0.5}
                underlayColor='rgba(0, 47, 86, 0.0)'
                onPress={() => {
                 this.props.onFilterSelect(filter)
                //  this.setState({filter: filter.type})
                }}
                //ref={ref => this.FILTERS[index] = ref}
                key={index}
                >
                  {/* this.state.filter != filter.type ? "#a1a1a1": "#00609d" */}
                  <View style={[styles.filter, {backgroundColor: getColor(filter.type)}]}>

                  {/* <FontAwesome5 name={filter.icon} size={25} color={filter.color}/> */}
                  <FilterIcon icon={filter.type}/>

                  </View>
                </TouchableHighlight>

                <Text style={styles.filterText}>
                  {filter.type}
                </Text>
              </View>
              )
            })
          }
          </View>
        </View>
        )
    }
}