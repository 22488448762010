import React, { Component } from "react";
import {
  Text,
} from "react-native";

import PropTypes from "prop-types";
import moment from "moment";

export default class Time extends Component {
  constructor(props) {
    super(props);

    this.DATA = {
      date: localStorage.getItem("customDate"),
      time: JSON.parse(localStorage.getItem("customTimer")),
    };

    this.state = {
      curTime: "",
    };
  }

  static propTypes = {
    style: PropTypes.object,
    type: PropTypes.string,
  };
  
  static getSavedDate = () => {
    let date = localStorage.getItem("customDate");
    return date != null ? date : moment().format("MMMM D, YYYY");
  };

  componentDidMount() {
    this.state.curTime = this.setTime();
    this.currentTimeInterval = setInterval(
      () => (this.state.curTime = this.setTime()),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.currentTimeInterval);
    //console.log(this.state.curTime);
  }

  setTime = () => {
    switch (this.props.type) {
      case "timeRemaining":
        this.startTimer();
        break;

      case "currentTime":
        this.getCurrentTime();
        break;

      case "currentDate":
        this.getCurrentDate();
        break;

      default:
        this.getCurrentTime();
        break;
    }
  };

  startTimer = () => {
    
    try {
      let savedTime = this.DATA.time;

      if (!savedTime.disabled) {
        var current = moment(moment().format("HH:mm"), "HH:mm");

        var end = moment(
          moment(savedTime.time, "h:mm A").format("HH:mm"),
          "HH:mm"
        );
        var duration = moment(
          moment.utc(end.diff(current)).format("H:mm"),
          "H:mm"
        );

        // //console.log(end)
  
        if (duration.format("H:mm") === "0:00") {
          clearInterval(this.currentTimeInterval);
          localStorage.setItem("customTimer", JSON.stringify({
            time: savedTime.time,
            duration: savedTime.duration,
            disabled: true
          }));
  
          var text = "Your Timer is Up!";
          //console.log(text);
          alert(text);
          let notification = new Notification("Your Timer Has Ended", {
            body: text,
          });
        }
  
        var hour = duration.format("H") != "0" ? duration.format("H") + "h" : "";
        var minute = duration.format("mm") + "min";
  
        var time = hour + " " + minute;
        //   //console.log(time)
        this.setState({ curTime: time });
        return time;
      }
      var time = "00min";
      this.setState({ curTime: time });
      return time;
      
    } catch (e) {
      console.error(e);
    }
  };

  getCurrentTime = () => {
    let time = new Date();
    let current =
      time.toLocaleTimeString().length < 11
        ? time
            .toLocaleTimeString()
            .replace(time.toLocaleTimeString().substring(4, 8), " ")
        : time
            .toLocaleTimeString()
            .replace(time.toLocaleTimeString().substring(5, 8), " ");

    this.setState({ curTime: current });
    return current;
  };

  getCurrentDate = () => {
    // let time = new Date();
    let current = moment().format("MMMM D, YYYY");

    this.setState({ curTime: current });
    return current;
  };

  render() {
    return <Text style={this.props.style}>{this.state.curTime}</Text>;
  }
}
