import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Animated,
} from "react-native";

import PropTypes from "prop-types";

export default class FilterIcon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            icon: this.props.icon
        }
    }

    static propTypes = {
        icon: PropTypes.string.isRequired
    }

    iconSwitch = () => {
        // //console.log(this.props.icon)
        switch(this.props.icon) {
            case "essential":
                return <img src={require('../../img/filters/filter-icon-essentials.svg')}/>

            case "food":
                return <img src={require('../../img/filters/filter-icon-food.svg')}/>
            
            case "fun":
                return <img src={require('../../img/filters/filter-icon-fun.svg')}/>
            
            case "my tour":
                return <img src={require('../../img/filters/filter-icon-my-tour.svg')}/>
            
            case "popular":
                return <img src={require('../../img/filters/filter-icon-popular.svg')}/>
            
            case "sights":
                return <img src={require('../../img/filters/filter-icon-sights.svg')}/>
            
            default:
                return <img src={require('../../img/filters/filter-icon-food.svg')}/>
        }
    }

    render() {
        return(
            <View>
                <this.iconSwitch/>
            </View>
            
        )
    }

}