import React, { Component } from "react";
import {
  View,
  Image,
  Animated
} from "react-native";
import ReactPlayer from "react-player";

import { LinearGradient } from "expo-linear-gradient";

import styles from "./FrontStyles";

import Tours from "./components/Tours";
import Departure from "./components/DepatureTimers";
import BoardingZones from "./components/BoardingZones";
import CustomTimer from "./components/CustomTimer";
import CustomDate from "./components/CustomDate";
import Acknowledgement from "../Disclaimer";

import RequestDestinations from "./RequestDestinations";
import askNotificationPermission from "../Notifications";

export default class Front extends Component {
  constructor(props) {
    super(props);

    askNotificationPermission();
    this.requestDestinations = new RequestDestinations();

    this.DATA = {
      timer: JSON.parse(localStorage.getItem("cruise")),
      tour: localStorage.getItem("tour"),
    };

    this.state = {
      slide: localStorage.getItem("disclaimerAgreed") ? "tours" : "disclaimer", // available slides: tours, departure-timer, none
    };

    this.getDestinations();
  }

  fade = new Animated.Value(1);
  color = new Animated.Value(0);

  componentDidMount() {
    if (this.state.slide !== "disclaimer") {
      Animated.timing(this.color, {
        toValue: 1,
        duration: 0,
        useNativeDriver: true
      }).start();
    }
  }

  getDestinations = () => {
    this.requestDestinations.getDestinations((destinations, raw) => {
      this.requestDestinations.getTerminals((terminals) => {
        let contentful = {
          destinations: destinations,
          terminals: terminals,
          raw: raw,
        };
        //console.log(contentful);
  
        localStorage.setItem("contentful", JSON.stringify(contentful));
        //console.log(destinations);
      });
    });
  };

  // Navigates to the next screen when the user selected a tour
  onTourSelect = () => {
    //console.log(this.DATA.tour);
  };

  // changes the slides or modals
  // ex: When 'SET TIMER' is pressed it switches between menus
  onSlideSelect = (slide) => {
    Animated.timing(
      this.fade,
      {
        toValue: 0,
        duration: 200,
        useNativeDriver: true
      }
    ).start();

    Animated.timing(this.color, {
      toValue: 0,
      duration: 400,
      useNativeDriver: true
    }).start();

    setTimeout(() => {
      this.setState({ slide: slide });
      Animated.timing(
        this.fade,
        {
          toValue: 1,
          duration: 200,
          useNativeDriver: true
        }
      ).start();

      Animated.timing(this.color, {
        toValue: .9,
        duration: 400,
        useNativeDriver: true
      }).start();
    }, 200);
    
  };

  onCruiseSelect = (cruise) => {
    this.cruise = cruise;
    // this.setState({ slide: "boarding-zones" });
    this.setState({ slide: "tours" });
  };

  showSlide = () => {

    var color = this.color.interpolate({
      inputRange: [0, 1],
      outputRange: ["rgba(13, 84, 138, .9)", "rgba(13, 84, 138, 0)"]
    });

    switch (this.state.slide) {
      case "disclaimer": 
        return (
          <Acknowledgement onSlideSelected={e => this.onSlideSelect(e)}></Acknowledgement>
        );

      case "tours":
        return (
          <Animated.View style={[styles.slideContainer, {backgroundColor: color}]}>
            <Animated.View style={[styles.animatedContainer, {opacity: this.fade}]}>
              <Tours
                onTourSelected={(tour) => this.onTourSelect(tour)}
                onSlideSelected={(slide) => this.onSlideSelect(slide)}
              />
            </Animated.View>
          </Animated.View>
          
          
        );

      case "departure-timer":
        return (
          <View style={styles.slideContainer}
          >
          <Animated.View style={[styles.animatedContainer, {opacity: this.fade}]}>
              <Departure
                onSlideSelected={(slide) => this.onSlideSelect(slide)}
                onCruiseSelected={(cruise) => this.onCruiseSelect(cruise)}
              />
          </Animated.View>

          </View>
        );

      case "boarding-zones":
        return (
          <View style={styles.slideContainer}>
            <Animated.View style={[styles.animatedContainer, {opacity: this.fade}]}>
              <BoardingZones
              onSlideSelected={(slide) => this.onSlideSelect(slide)}
              cruise={this.cruise}
              />
            </Animated.View>
          
          </View>
          
        );

      case "custom-timer":
        return (
          <View style={styles.slideContainer}>
            <Animated.View style={[styles.animatedContainer, {opacity: this.fade}]}>
            <CustomTimer onSlideSelected={(slide) => this.onSlideSelect(slide)} />
            </Animated.View>
          
          </View>
          
        );

      case "custom-date":
        return (
          <View style={styles.slideContainer}>
            <Animated.View style={[styles.animatedContainer, {opacity: this.fade}]}>
            <CustomDate onSlideSelected={(slide) => this.onSlideSelect(slide)} />
            </Animated.View>
          
          </View>
        );

      case "none":
        return <View />;

      default:
        return <Tours onTourSelected={(tour) => this.onTourSelect(tour)} />;
    }
  };

  render() {
    var boatImage = require("./img/bg-default-hero.jpg");
    var logo = require("./img/logo.png");
    var carnival = require("./img/iconsport/logo-royal-caribean.png");
    var background = require("./img/bg-bottom.jpg")

    return (
      <View style={styles.container}>
        <View style={{height: "100%", width: "100%", alignSelf: "center", overflow: "hidden",
        maxWidth: 460}}>

        <View style={{width: "100%", height: "100%"}}>
          <View style={styles.boatImage}>
            <ReactPlayer
              url={require("./img/intro.mp4")}
              playing={true}
              loop={true}
              width={"850px"}
              height={"400px"}
              muted={true}
            />
          </View>

          <Image source={background} style={styles.background}/>
        </View>
        

        <LinearGradient
          colors={[
            "rgba(0, 47, 86, 1)",
            "rgba(0, 47, 86, 0)",
          ]}
          locations={[0, 1]}
          style={styles.gradient}
        />

        <LinearGradient
          colors={[
            "rgba(201, 235, 255, 0)",
            "rgba(201, 235, 255, .5)",
            "rgba(201, 235, 255, .8)",
            "rgba(201, 235, 255, 1)",
            "rgba(201, 235, 255, 1)",
            "rgba(201, 235, 255, 1)",
            "rgba(201, 235, 255, .8)",
            "rgba(201, 235, 255, .7)",
            "rgba(201, 235, 255, .6)",
            "rgba(201, 235, 255, .5)",
            "rgba(201, 235, 255, 0.3)",
            "rgba(201, 235, 255, 0.1)",
            "rgba(201, 235, 255, 0)",
            "rgba(201, 235, 255, 0)",
          ]}
          // locations={[0, 0, 0, 0, 0]}
          style={[styles.gradientBottom]}
        />

        <View style={{width: "100%", height: "100%", position: "absolute"}}>
          <View style={{width: "100%", height: "100%", flexDirection: "row", justifyContent: "space-between"}}>

            <View style={{alignItems: "center", marginLeft: 40}}>
            <Image source={logo} style={styles.logo} resizeMode={"contain"}/>
            </View>

            <View style={{alignItems: "center", marginRight: 40}}>
            <Image source={carnival} style={styles.logo} resizeMode={"contain"}/>
            </View>
          
          
          </View>
        </View> 
        
        <this.showSlide/>
        </View>
        
        
      </View>
    );
  }
}
