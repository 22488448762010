import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  SafeAreaView,
  Animated
} from "react-native";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import styles from "./TutorialStyles";
import YouTubePlayer from "react-player/youtube";
import { BlurView } from "expo-blur";

export default class Tutorial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            page: 0,
            slides: this.props.slides
        }
    }

    static propTypes = {
        slides: PropTypes.any,
        onClose: PropTypes.func
    };

    fade = new Animated.Value(0);

    componentDidMount() {
        Animated.timing(
            this.fade,
            {
              toValue: 1,
              duration: 200,
              useNativeDriver: true
            }
          ).start();
    }

    renderSlide = (e) => {
        return(
            <View style={styles.slide}>
                <Image source={e.url} style={styles.slideContent}/>
            </View>
        )
    }

    renderPagination = (e) => {
        return(
            <View style={{width: 10, height: 10, 
            backgroundColor: e.id-1 == this.state.page ? "grey" : "lightgrey", 
            marginRight: 10, borderRadius: 15}}>

            </View>
        )
    }
    
    onScroll = (event) => {
        let yOffset = event.nativeEvent.contentOffset.x;
        let contentWidth = event.nativeEvent.contentSize.width;
        let value = Math.round(((contentWidth-250) - ((contentWidth-250)-yOffset)) / 250);
        if (value != this.state.page) {
            this.setState({page: value});
        }
    }

    close = async() => {
        await this.setState({page: 0});

            Animated.timing(
            this.fade,
            {
              toValue: 0,
              duration: 200,
              useNativeDriver: true
            }
          ).start();
        setTimeout(() =>  {
            this.props.onClose();
        }, 200);
        
    }

    render() {
        return(
            <Animated.View style={[styles.container, {opacity: this.fade}]} >
            <BlurView style={styles.blurContainer} intensity={10}>
                <View style={styles.blurContainer}>

                
                <View style={{flexDirection: "row", width: 250, margin: 20, alignItems: "space-between"}}>
                        <Text style={styles.tutorialText}>
                            STARTING A TOUR
                        </Text>
                        <TouchableOpacity style={{left: 50}} onPress={this.close}>
                            <MaterialCommunityIcons name="close-thick" size={30} color="white"/>
                        </TouchableOpacity>
                </View>

                <View style={styles.modal}>
                    <View style={styles.list}>
                    <FlatList
                    renderItem={(e) => this.renderSlide(e.item)}
                    data={this.state.slides}
                    keyExtractor={(item) => item.id}
                    horizontal={true}
                    pagingEnabled={true}
                    showsHorizontalScrollIndicator={false}
                    onScroll={this.onScroll}
                    ref={e => this.list = e}
                    ></FlatList>
                    </View>
                    
                    <View style={styles.pagination}>
                    <FlatList
                    renderItem={(e) => this.renderPagination(e.item)}
                    data={this.state.slides}
                    keyExtractor={(item) => item.id}
                    horizontal={true}
                    />
                    </View>
                    
                </View>
                </View>
            </BlurView>
            </Animated.View>
        );
    }
}