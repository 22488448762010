import zIndex from '@material-ui/core/styles/zIndex';
import {
    StyleSheet
  } from 'react-native';
  
  export default StyleSheet.create({
      container: {
        ...StyleSheet.absoluteFillObject,
        flex: 0,
        justifyContent: "flex-end",
        alignItems: "center",
      },
      
      image: {
        flex: 1,
        width: 200,
        height: 200,
        resizeMode: 'contain',
        shadowOffset: {width: 0, height: 5 },
        shadowColor: 'black',
        shadowOpacity: 0.6
      },
      addRoute: {
        backgroundColor: "rgba(255, 255, 255, 1)",
        width: 25,
        height: 25,
        borderRadius: 15,
  
        justifyContent: "center",
        alignItems: "center"
      },
      filterText: {
        color: "#a1a1a1",
        fontWeight: "bold",
        alignSelf: "center",
        fontSize: 12,
        textTransform: "capitalize"
        
      },
      filterContainer: {
        flex: 1,
        justifyContent: "space-evenly"
        // top: 180
      },
      filter: {
        flex: 0,
        backgroundColor: "#a1a1a1",
        borderRadius: 20,
        minWidth: 40,
        minHeight: 40,
        justifyContent: "center",
        alignItems: "center"
      },

  
      // cardListContainer: {
      //   backgroundColor: 'rgba(0, 0, 0, .2)',
      //   height: 80,
      //   width: 230,
      //   padding: 15,
      //   borderRadius: 15,
      //   borderWidth: 0,
      //   borderColor: "white",
      //   margin: 10,
      //   shadowRadius: 0
      // },
  
      cardContainer: {
        backgroundColor: 'rgba(0, 0, 0, .4)',
        height: "100%",
        width: "100%",
        padding: 15,
        borderRadius: 15,
        borderWidth: 0,
        borderColor: "white"
        // flexDirection: "row",
        // alignContent: "space-between"
  
      },

      cardList: {
        flexDirection: "row",
        borderBottomWidth: 2,
        borderBottomColor: "lightgray",
        borderStyle: "dashed",
        borderRadius: 1
      },

      cardListContainer: {
        backgroundColor: 'rgba(0, 0, 0, .0)',
        height: "100%",
        width: "100%",
        // padding: 15,
        // borderRadius: 15,
        borderWidth: 0,
        borderColor: "white",
        // flexDirection: "row",
        // alignContent: "space-between"
  
      },
      
      cardPressed: {
        backgroundColor: 'rgba(0, 0, 0, .5)',
        height: 200,
        width: 230,
        padding: 15, //24
        borderRadius: 15,
        borderWidth: 2,
        borderColor: "#2b79b5"
      },
      cardImage: {
        width: 200,
        height: 200,
        left: 30,  
        resizeMode: 'contain',
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
      },
      cardTitle: {
        color: 'white',
        fontSize: 16,
        // bottom: 100,
        fontWeight: "bold",
  
      },
      blurView: {
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          borderRadius: 15
      },
      carousel: {
        flex: 1,
        width: "100%",
        height: "100%",
        overflow: "scroll"
      },
      carouselBlock: {
        //borderRadius: 25,
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        backgroundColor: 'white',
        // position: "absolute",
        // width: "100%",
        // height: "100%",
        
        elevation: 10,
        shadowColor: 'gray',
        shadowOffset: { width: 0, height: -2},
        shadowOpacity: 0.5,
        shadowRadius: 3, 
    }
    });
    