import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native";

import { LinearGradient } from "expo-linear-gradient";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import styles from "./ToursStyles";
import Time from "../../Time";
import {useUrlParams, NewLink} from "../../../UrlHandler";

import ReactGA from "react-ga4";
import { StylesProvider } from "@material-ui/styles";

export default class TourSelect extends Component {
  constructor(props) {
    super(props);

    this.DATA = {
      cruise: JSON.parse(localStorage.getItem("cruise")),
      boarding: JSON.parse(localStorage.getItem("boarding")),
      tour: localStorage.getItem("tour"),
      customTimer: JSON.parse(localStorage.getItem("customTimer")),
    };

    this.state = {
      time: this.DATA.customTimer,
    };
  }

  static propTypes = {
    onTourSelected: PropTypes.func.isRequired,
    onSlideSelected: PropTypes.func.isRequired,
  };

  render() {
    var riverwalk = require("../img/map-1.jpg");
    var ybor = require("../img/map.jpg");

    var time = this.state.time;
    /** Set to custom timer if set, otherwise set to selected cruise  */
    var tourObj = this.DATA.cruise ? this.DATA.cruise : time;
    var boarding = this.DATA.boarding;

    return (
      <View style={styles.buttonContainer}>
        {/* Set Timer Button */}

        <TouchableHighlight
          style={[styles.button, styles.shadow]}
          onPress={() => this.props.onSlideSelected("departure-timer")}
          // activeOpacity={1}
          underlayColor="rgba(0, 47, 86, .5)"
        >
          <View style={styles.buttonContentContainer}>
            <View style={styles.buttonImageContainer}>
              <Image
                source={require("../img/iconsport/icon-timer.png")}
                resizeMode={"contain"}
                style={styles.buttonIcon}
              />
            </View>

            <View style={{justifyContent: "center", alignSelf: "center"}}>
            {tourObj != undefined ? (
              <View>
                <View style={{ flexDirection: "column" }}>
                  <Text
                    style={[
                      styles.timeText,
                      { fontSize: 28, fontWeight: "bold", marginTop: 5 },
                    ]}
                  >
                    {this.DATA.customTimer ? (
                      <Time type={"timeRemaining"} />
                    ) : boarding ? (
                      <Time type={"timeRemaining"} />
                    ) : (
                      ""
                    )}
                  </Text>
                  <Text
                    style={[
                      styles.bigText
                    ]}
                  >
                    Time Remaining
                  </Text>
                </View>

                {/* <View style={{alignSelf: "center"}}>
                                                <FontAwesome5 name="cog" size={15} color="white"/>
                                            </View> */}
              </View>
            ) : (
              <View
                style={{ alignItems: "flex-start", justifyContent: "center" }}
              >
                <Text
                  style={[
                    styles.bigText
                  ]}
                >
                  Set Cruise Timer
                </Text>
              </View>
            )}
            </View>
          </View>

          {/* {this.DATA.customTimer || this.DATA.cruise ? (
              <View
                style={[
                  {
                    height: 50,
                    backgroundColor: "#91001E",
                    borderRadius: 10,
                    bottom: 20,
                    zIndex: -1,
                  },
                ]}
              >
                {this.DATA.cruise ? (
                  

                  <View
                  style={[
                    {
                      alignItems: "flex-end",
                      bottom: 6,
                      flexDirection: "row",
                      justifyContent: "center",
                      flex: 1,
                    },
                  ]}
                  >
                  <Text
                    style={[
                      styles.cruiseInfo,
                      { textTransform: "uppercase" },
                    ]}
                  >
                    {tourObj
                      ? tourObj.cruiseline + " " + tourObj.cruise + " • "
                      : ""}
                  </Text>

                  <Text style={[styles.cruiseInfo, { fontSize: 16 }]}>
                    {boarding
                      ? "Boarding " +
                        boarding.boarding[0] +
                        " - " +
                        boarding.boarding[1]
                      : ""}
                  </Text>
                  </View>
                ) : (
                  <View
                    style={[
                      {
                        alignItems: "flex-end",
                        marginBottom: 6,
                        flexDirection: "row",
                        justifyContent: "center",
                        flex: 1,
                      },
                    ]}
                  >
                    <Text style={styles.cruiseInfo}>{"CUSTOM TIMER • "}</Text>

                    <Text style={[styles.cruiseInfo, { fontSize: 16 }]}>
                      {tourObj ? "Set for " + tourObj.time : ""}
                    </Text>
                  </View>
                )}
              </View>
            ) : (
              <View />
            )} */}
        </TouchableHighlight>

        {/* Tampa Riverwalk card */}
        <NewLink params="cruiseline" to={"/tour"} style={{ textDecoration: "none" }}>
          <TouchableHighlight
            style={[
              styles.button,
              styles.shadow,
              { backgroundColor: "rgba(14, 58, 110, 1)" },
            ]}
            onPress={() => {
              ReactGA.event({
                category: "select_item",
                action: "selected riverwalk tour",
              });

              localStorage.setItem("tour", "tampa");
              this.props.onTourSelected();
            }} // Change Screens
            underlayColor="rgba(0, 47, 86, .5)"
          >
            <View style={styles.buttonContentContainer}>
              <View style={styles.buttonImageContainer}>
                <Image
                  source={require("../img/iconsport/icon-riverwalk.png")}
                  resizeMode={"contain"}
                  style={styles.buttonIcon}
                />
              </View>

              <View
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Text
                  style={[
                    styles.smallText
                  ]}
                >
                  START YOUR TOUR
                </Text>
                <Text
                  style={[
                    styles.bigText
                  ]}
                >
                  Tampa Riverwalk
                </Text>
              </View>
            </View>
          </TouchableHighlight>
        </NewLink>

        <NewLink params="cruiseline" to={"/tour"} style={{ textDecoration: "none" }}>
          <TouchableHighlight
            style={[styles.button, styles.shadow, { backgroundColor: "rgba(14, 58, 110, 1)", opacity: 1}]}
            // disabled
            onPress={() => {
              ReactGA.event({
                category: "select_item",
                action: "selected ybor tour",
              });

              localStorage.setItem("tour", "ybor");
              this.props.onTourSelected();
            }} // Change Screens
            underlayColor="rgba(0, 47, 86, .5)"
          >
            <View style={styles.buttonContentContainer}>
              <View style={styles.buttonImageContainer}>
                <Image
                  source={require("../img/iconsport/icon-ybor.png")}
                  resizeMode={"contain"}
                  style={styles.buttonIcon}
                />
              </View>
              
              <View
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={[styles.smallText]}
                >
                  START YOUR TOUR
                </Text>
                <Text
                  style={[
                    styles.bigText
                  ]}
                >
                  Historic Ybor City
                </Text>
              </View>

            </View>
          </TouchableHighlight>
        </NewLink>
        
        <a style={{textDecoration: "none"}} href="https://www.porttb.com/parking">
          <TouchableHighlight
          style={[styles.button, styles.shadow, { backgroundColor: "rgba(255, 255, 255, 1)", height: 42.5}]}
          onPress={() => {
            ReactGA.event({
            category: "select_item",
            action: "purchase cruise tickets",
          });
        }}
          underlayColor="rgba(255, 255, 255, .5)"
          >
            <View>
              <Text style={[styles.smallText, {color: "rgba(14, 58, 110, 1)", fontSize: 18, fontWeight: "bold"}]}>
                Purchase Cruise Parking
              </Text>
            </View>

          </TouchableHighlight>
        </a>
        
      </View>
    );
  }
}
