import React, { Component } from "react";
import {
  View,
  Text,
  TouchableHighlight,
  FlatList,
  TouchableOpacity,
  Image,
} from "react-native";

import PropTypes from "prop-types";

import { AntDesign } from "@expo/vector-icons";
import styles from "./DestinationListStyles";
import styles2 from "./DrawerStyles";

export default class DestinationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredData: [],
      excludeDestination: "*id here*",
      destinations: this.props.destinations,
    };
  }

  // componentDidMount() {
  //   this.setState({filteredData: this.props.filteredData.filter((data) => { return data.placeId != "parking"})})
  // }

  static propTypes = {
    filters: PropTypes.array,
    filteredData: PropTypes.array,
    onCardPressed: PropTypes.func,
    onFilterSelect: PropTypes.func,
    renderItems: PropTypes.func,
    opacity: PropTypes.number,
    selectedDestinations: PropTypes.array,
    onOpenDetails: PropTypes.func.isRequired,
    onRemoveDestination: PropTypes.func.isRequired,
    onAddDestination: PropTypes.func.isRequired,
    hideCards: PropTypes.bool.isRequired,
  };

  Remove = (item) => {
    this.props.onRemoveDestination(item);
  };


  horizontalItem = ({ item }) => {
    return (
      <View key={item.id}>
        <TouchableHighlight
          activeOpacity={1}
          underlayColor="rgba(0, 47, 86, 0.0)"
          onPress={() => this.props.onOpenDetails(item)}
        >
          <View
            style={{
              width: 170,
              height: 125,
              borderRadius: 15,
              margin: 10,
              display: "flex",
              overflow: "hidden",
            }}
          >
            <Image
              source={{ uri: item.thumbnail }}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
              blurRadius={0}
            />

            <View
              style={styles2.cardContainer}
              ref={(item) => (this._card = item)}
            >
              {/* set Route Button */}
              <TouchableOpacity
                style={
                  this.props.selectedDestinations.includes(item.id)
                    ? [
                        styles2.addRoute,
                        {
                          transform: "rotate(45deg)",
                          backgroundColor: "white",
                        },
                      ]
                    : [styles2.addRoute]
                }
                onPress={() => this.props.onAddDestination(item)}
              >
                <View>
                  <AntDesign
                    name="plus"
                    size={20}
                    color={
                      this.props.selectedDestinations.includes(item.id)
                        ? "darkred"
                        : "rgba(41, 100, 147, 1)"
                    }
                  />
                </View>
              </TouchableOpacity>

              <View
                style={{
                  height: "75%",
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <Text style={[styles2.cardTitle]}>{item.name}</Text>
              </View>
            </View>
          </View>
        </TouchableHighlight>
      </View>
    );
  };

  verticalItem = ({ item }) => {
    // this.componentDidUpdate = function(){
    //   this._carousel.scrollToIndex({animated: false, index: 0});
      
    // }
    
    return (
      <View style={styles.cardList} key={item.id}>
        <TouchableHighlight
          activeOpacity={0.5}
          underlayColor="rgba(0, 47, 86, 0.0)"
          onPress={() => this.props.onOpenDetails(item)}
        >
          <View
            style={{
              width: 145,
              height: 105,
              borderRadius: 15,
              margin: 10,
              display: "flex",
              overflow: "hidden",
            }}
          >
            <Image
              source={{ uri: item.thumbnail }}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
              blurRadius={0}
            />

            <View
              style={styles.cardContainer}
              ref={(item) => (this._card = item)}
            >
              {/* set Route Button */}
              <TouchableOpacity
                style={
                  this.props.selectedDestinations.includes(item.id)
                    ? [
                        styles2.addRoute,
                        {
                          transform: "rotate(45deg)",
                          backgroundColor: "white",
                        },
                      ]
                    : [styles2.addRoute]
                }
                onPress={() => this.Remove(item)}
              >
                <View>
                  <AntDesign
                    name="plus"
                    size={20}
                    color={
                      this.props.selectedDestinations.includes(item.id)
                        ? "darkred"
                        : "rgba(41, 100, 147, 1)"
                    }
                  />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </TouchableHighlight>

        <View
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          {/* Name */}
          <Text style={[styles.cardTitle, { color: "#00437d", marginTop: 10, width: "50%"}]}>
            {item.name}
          </Text>

          <Text
            style={[
              styles.cardTitle,
              { color: "#00437d", fontWeight: "normal" },
            ]}
          >
            {item.address}
          </Text>
          <Text
            style={[
              styles.cardTitle,
              { color: "#00437d", fontWeight: "normal" },
            ]}
          >
            {item.phoneNumber}
          </Text>
        </View>
      </View>
    );
  };

  empty = () => {
    return(
      <Text style={styles.emptyText}>
              Your tour is empty. Make sure to add some destinations!
        </Text>
    );
  };

  List = () => {
    var data = this.props.filteredData.filter((data) => { return data.placeId != "parking" && data.placeId != "trolly"});
    return (
          data.length > 0 ?
          <FlatList
            ref={(c) => {
              this._carousel = c;
            }}
            data={data}
            renderItem={this.props.opened ? this.verticalItem : this.horizontalItem}
            removeClippedSubviews={true}
            horizontal={!this.props.opened}
            
            ListFooterComponent={<View style={{margin: 20}}/>}
          />
          :
          <Text style={styles.emptyText}>
              Your tour is empty. Make sure to add some destinations!
          </Text>
    );
  };

  render() {
    // this._carousel.shouldComponentUpdate = () => {
    //   // this._carousel.scrollToIndex
    //   //console.log("test")
    // }
    return (
      <this.List />  
    );
  }
}
