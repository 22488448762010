import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, FlatList } from "react-native";

import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import styles from "./DepatureTimersStyles";

import { useUrlParams} from '../../../UrlHandler';
import Time from "../../Time";
import moment from "moment";
import RequestCruises from "../RequestCruises";
import CircularProgress from "@material-ui/core/CircularProgress";
import toUnicodeVariant from "../ToUnicodeVariant";

import ReactGA from "react-ga4";
export default class TourSelect extends Component {
  constructor(props) {
    super(props);

    this.CRUISES = require("../../../data/cruises.json");
    this.FILTERED_CRUISES = {};

    this.DATA = {
      cruise: JSON.parse(localStorage.getItem("cruise"))
        ? JSON.parse(localStorage.getItem("cruise"))
        : "",
      tour: localStorage.getItem("tour"),
      date: Time.getSavedDate(),
    };

    this.state = {
      cruise: this.DATA.cruise,
      cruises: {},
    };

    this.cruises = new RequestCruises();
  }

  static propTypes = {
    onSlideSelected: PropTypes.func.isRequired,
    onCruiseSelected: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.setCruises = function () {
      console.warn("Closed out of departure timers before data was fetched.");
    };
  }

  componentDidMount() {
    this.filterCruises();
  }

  setCruises = (items) => {
    this.setState({ cruises: items });
  };

  filterCruises = () => {
    function getURLParams(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get('cruiseline');
    }

    this.cruises.getCruises((data) => {

      if (data.events.length > 0) {
        //console.log(data.events);

        var placeholder = {
          cruiseline: "carnival",
          cruise: "paradise",
          date: "2021-9-5",
          zones: [
            {
              boarding: ["12:30pm", "1:00pm"],
              time_remaining: "1h 55min",
              id: 1,
            },
            {
              boarding: ["1:30pm", "2:00pm"],
              time_remaining: "3h 55min",
              id: 2,
            },
            {
              boarding: ["2:00pm", "2:30pm"],
              time_remaining: "4h 55min",
              id: 3,
            },
            {
              boarding: ["2:30pm", "3:00pm"],
              time_remaining: "2h 55min",
              id: 4,
            },
            {
              boarding: ["3:00pm", "3:30pm"],
              time_remaining: "5h 55min",
              id: 5,
            },
          ],
          terminal: 2,
          id: 1,
        };

        var cruises = [];
        data.events.map((e) => {
          var split = e.title.split(" ");
          var cruiseline = split[0].replace('\t', ''); //always carnival so no need to get string from array
          
          var cruise = split[1];
          var terminal = e.facility.title.split(" ")[1];

          var cruiseline_text = "";

          switch (cruiseline) {
            case "CCL":
              cruiseline_text = "carnival";
              break;
            case "RCI":
              cruiseline_text = "royal-caribbean";
              break;
            case "NCL":
              cruiseline_text = "norwegian";
              break;
            case "CEL":
              cruiseline_text = "celebrity";
              break;
          }
          let param = getURLParams();
        
          if (((!param) || (param==="default")) || (cruiseline_text === param)) {
            cruises.push({
              cruiseline: cruiseline,
              cruise: cruise,
              zones: placeholder.zones,
              terminal: terminal,
              image: this.iconSwitch(cruiseline),
              id: e.id,
            });
          } 
        });
        if (!(cruises.length > 0)) {
          let message = {
            isMessage: true,
            message: `Uh Oh! Looks like there are no cruises on ${this.DATA.date}`,
            id: 0,
          };
          this.setCruises([message]);
        } else {

          this.setCruises(cruises);
        }

        
      } else {
        let message = {
          isMessage: true,
          message: `Uh Oh! Looks like there are no cruises on ${this.DATA.date}`,
          id: 0,
        };
        this.setCruises([message]);
      }
    });
  };


  
  // Sets state and sets the timer
  cardPressed = async (item) => {
    var searchParams = new URLSearchParams(window.location.search);

    if (item.id == this.DATA.cruise.id) {
      if (confirm("Are you sure you want to deselect this cruise?")) {
        await this.setState({ cruise: {} });
        await this.setState({ boarding: {} });
        this.DATA.cruise = {};

        localStorage.removeItem("cruise");
        localStorage.removeItem("boarding");
        localStorage.removeItem("customTimer");

         // Update URL params
        // searchParams.set("cruiseline", "default");
        // window.location.search = searchParams.toString();
      }
    } else {
      if (
        confirm(
          "By selecting this cruise, you must make sure your departure time matches."
        ) && confirm("In addition, by selecting 'Ok', you understand that this is "+ toUnicodeVariant('NOT', 'bold sans', 'bold') +" an audible alarm. You must have this site opened in order to be notified when the timer is up.")
      ) {
        await this.setState({ cruise: item });
        this.DATA.cruise = this.state.cruise;
        //console.log(this.state.cruise);
        console.log(item);

        localStorage.removeItem("customTimer");
        localStorage.setItem("cruise", JSON.stringify(item));
        var time = moment("3:00 pm", "h:mm a");
        // time.format("h:mm a");

        //console.log(time.format("h:mm a"));
        localStorage.setItem(
          "boarding",
          JSON.stringify({
            boarding: ["12:00 pm", "3:00 pm"],
            time_remaining: "3h 00min",
            id: 0,
          })
        );
        localStorage.setItem(
          "customTimer",
          JSON.stringify({
            time: time.format("h:mm a"),
            duration: moment("0:00", "H:mm"),
            disabled: false,
          })
        );

        this.props.onCruiseSelected(item);


        // Update URL params
        let cruiseline = "";
        switch (item.cruiseline) {
          case "CCL":
            cruiseline = "carnival";
          break;
          case "RCI":
            cruiseline = "royal caribbean";
          break;
          case "CEL":
            cruiseline = "celebrity";
          break;
          case "NCL":
            cruiseline = "norwegian";
          break;
          default:
            cruiseline = "default";
        }

        ReactGA.event({
          category: "select_timer",
          action: "selected " + cruiseline + " timer"
        });
        // searchParams.set("cruiseline", cruiseline);
        // window.location.search = searchParams.toString();
      }
    }
  };

  iconSwitch = (cruiseline) => {
    switch (cruiseline) {
      case "ccl":
        return require("../img/cruiselogos/logo-carnival.png");
      case "rci":
        return require("../img/cruiselogos/logo-royal-caribbean.png");
      case "ncl":
        return require("../img/cruiselogos/logo-norwegian.png");
      case "cel":
        return require("../img/cruiselogos/logo-celebrity.png");
    }
  };

  //  Cruise Card Items
  renderCruises = ({ item }) => {
        
    if (!item.isMessage) {
      return (
        <TouchableOpacity
          style={{ borderRadius: 15 }}
          activeOpacity={0.7}
          onPress={() => this.cardPressed(item)}
          key={item.id}
        >
          <View
            style={
              this.state.cruise != undefined && this.state.cruise.id == item.id
                ? [styles.card, { backgroundColor: "rgba(204, 0, 44, 1)" }]
                : styles.card // Switch between selected and unselected style
            }
          >
            <View style={{flexDirection: "row"}}>
              <Text
                style={
                  this.state.cruise != undefined &&
                  this.state.cruise.id == item.id
                    ? [
                        styles.cardText,
                        {
                          // fontStyle: "italic",
                          // fontWeight: "normal",
                          textTransform: "capitalize",
                          color: "white",
                        },
                      ]
                    : [
                        styles.cardText,
                        {
                          // fontStyle: "italic",
                          // fontWeight: "normal",
                          textTransform: "capitalize",
                        },
                      ]
                }
              >
                {item.cruiseline}
              </Text>

              <Text
                style={
                  this.state.cruise != undefined &&
                  this.state.cruise.id == item.id
                    ? [
                        styles.cardText,
                        { textTransform: "capitalize", color: "white" },
                      ]
                    : [
                        styles.cardText,
                        {
                          textTransform: "capitalize",
                          // color: "rgba(204, 0, 44, 1)",
                        },
                      ]
                }
              >
                {item.cruise}
              </Text>
            </View>
            
            <View style={{flexDirection: "row"}}>
              <Text style={[styles.cardText, {fontSize: 16, fontWeight: "normal", fontStyle: "italic", color: this.state.cruise != undefined &&
                    this.state.cruise.id == item.id ? "white": "#00437d"}]}>
                Final Boarding 3pm
              </Text>
              <Text style={{fontSize: 16, fontWeight: "normal", fontStyle: "italic", color: this.state.cruise != undefined &&
                    this.state.cruise.id == item.id ? "white": "#CE002B"}}>
              *
              </Text>
            </View>
            

            {/* 
            <View style={styles.imageContainer}>
              <Image
                source={item.image}
                style={{ width: "100%", height: "100%", margin: 0 }}
              />
            </View> */}
          </View>
        </TouchableOpacity>
      );
    } else {
      return (
        <View style={styles.card} key={item.id}>
          <Text style={styles.messageText}>{item.message}</Text>
        </View>
      );
    }
  };

  render() {
    // //console.log(this.state.timer);

    return (
      <View style={styles.container}>
        <View style={styles.departureContainer}>
          <View
            style={{
              flexDirection: "row",
              margin: 30,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text style={[styles.centerText]}>SET CRUISE TIMER</Text>

            <TouchableOpacity
              style={{ top: 5 }}
              onPress={() => {
                this.props.onSlideSelected("tours");
              }}
            >
              <MaterialCommunityIcons
                name="close-thick"
                size={30}
                color="white"
              />
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            onPress={() => {
              this.props.onSlideSelected("custom-date");
            }}
            style={styles.date}
          >
            <Text style={[styles.centerText, {fontSize: 20}]}>
              {this.DATA.date ? this.DATA.date : <Time type="currentDate" />}
            </Text>

            <View
              style={{
                marginLeft: 10,
                marginRight: 10,
                justifyContent: "center",
              }}
            >
              <FontAwesome5 name="calendar-alt" size={18} color="white" />
            </View>
          </TouchableOpacity>

          <View style={styles.departureTimers}>
            {this.state.cruises.length > 0 ? (
              <View />
            ) : (
              <View style={[styles.card, {flexDirection: "row", alignItems: "center", justifyContent:"space-between"}]}>
                
                <Text style={[styles.messageText, {fontSize: 20}]}>Looking for cruises...</Text>
                <CircularProgress style={{ color: "#00437d", margin: 10 }} />
              </View>
              
            )}

            <FlatList
              style={styles.cardList}
              data={this.state.cruises}
              renderItem={this.renderCruises}
              keyExtractor={(item) => item.id}
              horizontal={false}
            />
          </View>

          <TouchableOpacity
            style={styles.date}
            onPress={() => {
              this.props.onSlideSelected("custom-timer");
            }}
          >
              
              <Text
                style={[styles.centerText, {fontSize: 20}]}
              >
                Set Custom Timer
              </Text>

              <View
              style={{
                marginLeft: 10,
                marginRight: 10,
                justifyContent: "center",
              }}
            >
              <FontAwesome5 name="user-clock" size={18} color="white" />
            </View>

          </TouchableOpacity>

          <TouchableOpacity
            style={[
              styles.customTimer,
              {
                height: 50,
                backgroundColor: "rgba(18, 57, 108, 1)",
                justifyContent: "center",
              },
            ]}
            onPress={() => {
              if (confirm("Are you sure that you want to reset your timer?")) {
                localStorage.removeItem("customTimer");
                localStorage.removeItem("cruise");
                localStorage.removeItem("boarding");
                this.setState({ cruise: "" });
              }
            }}
          >
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <MaterialCommunityIcons
                name="close-thick"
                size={30}
                color="white"
              />
              <Text
                style={{
                  fontSize: 24,
                  fontWeight: "bold",
                  color: "white",
                  margin: 10,
                }}
              >
                CLEAR TIMER
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}
