import {
    StyleSheet
  } from 'react-native';
  
  export default StyleSheet.create({
container: {
    ...StyleSheet.absoluteFillObject,
    // backgroundColor: "rgba(41, 100, 147, 0.9)",
  },

  cardContainer: {
    width: "85%",
    height: "85%",
    backgroundColor: "white",
    alignSelf: "center",
    borderRadius: 10,
    alignItems: "center",
    marginBottom: 30,
    overflow: "hidden"
  },

  pictureContainer: {
    top: 0,
    width: "95%",
    height: 150,
    resizeMode: "contain",
    margin: 10,
    overflow: "hidden",
    
    backgroundColor: "lightgray",
    borderRadius: 5
  },

  zoneContainer: {
    flex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    overflow: "scroll"
  },

  zone: {
      flex: 1,
    width: "100%",
    height: 80,
    backgroundColor: "rgba(234, 234, 234, 1)",
    flexDirection: "column"
  },

  selectedZone: {
    flex: 1,
    width: "100%",
    height: 80,
    backgroundColor: "rgba(204, 0, 44, 1)",
    flexDirection: "column"
  },

  title: {
    alignSelf: "flex-start",
    margin: 10
  },

  redText: {
    color: "rgba(204, 0, 44, 1)",
    fontWeight: "bold"
  },

  blueText: {
    color: "#00437d",
    fontStyle: "italic"
  },

  whiteText: {
    color: "white",
    fontStyle: "italic"
  },

  boardingContainer: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    transform: [{scale: document.body.scrollWidth / 414}]
      // backgroundColor: "rgba(0, 62, 120, .95)",
      // // borderRadius: 15
  },

  centerText: {
    flex: 1, 
    //position: "absolute", 
    alignSelf: "center",
    // top: 10,

    color: "white",
    fontWeight: "bold",
    fontSize: 25
  },
});