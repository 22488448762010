
import {
    StyleSheet,
    Dimensions
  } from 'react-native';
  
  export default StyleSheet.create({
    container: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: "#0D548A",
    },

    animatedContainer: {
      ...StyleSheet.absoluteFillObject,
    },

    slideContainer: {
      ...StyleSheet.absoluteFillObject,
        backgroundColor: "rgba(13, 84, 138, .9)",
    },

    boatImage: {
        position: "relative",
        alignSelf: "center",
        justifyContent: "center",
        resizeMode: "contain"
    },
    logo: {
        position: "relative",
        width: 150,
        height: 100,
    },
    background: {
      flex: 1,
      width: "100%",
      resizeMode: "cover",
  },
    gradient: {
        flex: 1,
        flexDirection: "row",
        position: "absolute", 
        left: 0, 
        right: 0, 
        top: 0, 
        height: 120,
        justifyContent: "space-between",
        alignItems: "center"
      },

      gradientBottom: {
        flex: 1,
        flexDirection: "row",
        position: "absolute", 
        left: 0, 
        right: 0, 
        top: 250,
        height: 550,
        justifyContent: "space-between",
        alignItems: "center"
      }
  })