export default function askNotificationPermission() {
    function handlePermission(permission) {
      if(permission === 'denied') {
        //console.log("notification disabled")
        // alert("please enable notifications to get the app's full functionality!");
      } else {
        //console.log("notification enabled")
        // var notification = new Notification('To do list', { body: "Testing!" });
      }
    }

    function checkNotificationPromise() {
        try {
            // alert("please enable notifications to get the app's full functionality!");
            Notification.requestPermission().then();
        } catch(e) {
            return false;
        }

        return true;
    }
  
    if (!('Notification' in window)) {
      //console.log("This browser does not support notifications.");
    } else {
      if(checkNotificationPromise()) {
        Notification.requestPermission()
        .then((permission) => {
          handlePermission(permission);
        })
      } else {
        Notification.requestPermission(function(permission) {
          handlePermission(permission);
        });
      }
    }
  }