import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList
} from "react-native";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import PropTypes from "prop-types";
import styles from "./BoardingZonesStyles";

import Time from "../../Time";

export default class BoardingZones extends Component {
  constructor(props) {
    super(props);

    this.DATA = {
      cruise: JSON.parse(localStorage.getItem("cruise")),
      boarding: JSON.parse(localStorage.getItem("boarding")),
      tour: localStorage.getItem("tour"),
    };

    // Place holder data
    this.CRUISES = require("../../../data/cruises.json");
    //this.SELECTED_CRUISE = this.CRUISES[0];

    this.state = {
      timer: this.DATA.cruise,
      boarding: this.DATA.boarding,
      cruise: this.props.cruise,
    };
  }

  static propTypes = {
    onSlideSelected: PropTypes.func.isRequired,
    cruise: PropTypes.object,
  };

  zonePressed = async (item) => {
    if (this.highlightZone(item)) {
      if (confirm("Are you sure you want to deselect this cruise?")) {
        await this.setState({ timer: {} });
        await this.setState({ boarding: {} });
        this.DATA.cruise = {};

        localStorage.removeItem("cruise");
        localStorage.removeItem("boarding");
      }
    } else {
      if (confirm("By selecting this cruise, you must make sure your departure time matches.")) {
        await this.setState({ timer: item });
        await this.setState({ boarding: item });

        this.DATA.cruise = this.state.timer;
        localStorage.setItem("cruise", JSON.stringify(this.state.cruise));
        localStorage.setItem("boarding", JSON.stringify(item));
        localStorage.removeItem("customTimer");
        // //console.log(this.state.boarding)
        this.props.onSlideSelected("tours");
      }
    }
  };

  /** Checks if the zone item in the cruise is selected */
  highlightZone = (item) => {
    if (this.state.boarding && this.DATA.cruise)
      return (
        item.id == this.state.boarding.id &&
        this.state.cruise.id == this.DATA.cruise.id
      );

    return false;
  };

  renderZones = ({ item }) => {
    //console.log(item == this.state.boarding);
    return (
      <TouchableOpacity
        style={[this.highlightZone(item) ? styles.selectedZone : styles.zone]}
        activeOpacity={0.5}
        onPress={() => this.zonePressed(item)}
      >
        <View>
          <View style={{ alignSelf: "flex-start", margin: 10, marginLeft: 15 }}>
            <Text
              style={[
                styles.blueText,
                !this.highlightZone(item)
                  ? { fontStyle: "normal", fontWeight: "bold" }
                  : { fontStyle: "normal", fontWeight: "bold", color: "white" },
              ]}
            >
              ZONE {item.id}
            </Text>

            <View style={{ flexDirection: "row" }}>
              <Text
                style={
                  !this.highlightZone(item)
                    ? styles.blueText
                    : [styles.blueText, { color: "white" }]
                }
              >
                Boarding @{" "}
              </Text>
              <Text
                style={[
                  styles.blueText,
                  !this.highlightZone(item)
                    ? { fontStyle: "normal", fontWeight: "bold" }
                    : {
                        fontStyle: "normal",
                        fontWeight: "bold",
                        color: "white",
                      },
                ]}
              >
                {item.boarding[0]} - {item.boarding[1]}
              </Text>
            </View>

            <View style={{ flexDirection: "row" }}>
              <Text
                style={
                  !this.highlightZone(item)
                    ? styles.blueText
                    : [styles.blueText, { color: "white" }]
                }
              >
                Time left -{" "}
              </Text>
              <Text
                style={
                  !this.highlightZone(item)
                    ? styles.redText
                    : [styles.redText, { color: "white" }]
                }
              >
                {item.time_remaining}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              height: 1,
              backgroundColor: "lightgray",
              alignSelf: "flex-end",
            }}
          />
        </View>
      </TouchableOpacity>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.boardingContainer}>
          <View style={{ flexDirection: "row", margin: 30 }}>
            <Text style={[styles.centerText]}>SELECT BOARDING ZONE</Text>

            <TouchableOpacity
              style={{ top: 5 }}
              onPress={() => {
                this.props.onSlideSelected("departure-timer");
              }}
            >
              <MaterialCommunityIcons
                name="close-thick"
                size={30}
                color="white"
              />
            </TouchableOpacity>
          </View>

          <View style={styles.cardContainer}>
            <View style={styles.pictureContainer}>
              <Image
                source={this.state.cruise.image}
                style={{ width: "100%", height: "100%" }}
              />
            </View>

            <View style={styles.title}>
              <View style={{ flexDirection: "row", marginBottom: 5 }}>
                <Text
                  style={[
                    styles.blueText,
                    { fontSize: 25, textTransform: "uppercase" },
                  ]}
                >
                  {this.state.cruise.cruiseline}{" "}
                </Text>
                <Text
                  style={[
                    styles.redText,
                    { fontSize: 25, textTransform: "uppercase" },
                  ]}
                >
                  {this.state.cruise.cruise}{" "}
                </Text>
              </View>

              <Text style={[styles.blueText, { fontSize: 18 }]}>
                <Time type="currentDate" />
              </Text>
            </View>

            <View style={styles.zoneContainer}>
              <View
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "lightgray",
                  alignSelf: "flex-end",
                }}
              />
              <FlatList
                data={this.state.cruise.zones}
                renderItem={this.renderZones}
                keyExtractor={(item) => item.id}
                horizontal={false}
                contentContainerStyle={{ marginBottom: 42 }}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }
}
