import {
    StyleSheet
  } from 'react-native';
  
  export default StyleSheet.create({
    container: {
        ...StyleSheet.absoluteFillObject
    },

    userAgreement: {
        backgroundColor: "rgba(41, 100, 147, 0.9)",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        padding: 30,
        paddingTop: 50,
        paddingBottom: 50,
    },

      buttonsContainer: {
        top: 0,
        flexDirection: "row",
      },
    
      contentContainer: {
        display: "flex",
        height: "100%",
        backgroundColor: "white",
        borderRadius: 10,
        overflow: "hidden",
        padding: 20,
        
        flexDirection: "column",
      },
    
      textContainer: {
          flex: 1,
        alignItems: "center",
        height: "100%",
        // marginBottom: 10,
        // overflow: "scroll"
      },
    
      agree: {
        flex: 1,
        minWidth: "100%",
        height: 100,
        // marginRight: 10,
        marginTop: 20,
    
        alignSelf: "center",
    
        borderRadius: 5,
        backgroundColor: "rgba(204, 0, 44, 1)",
      },
    
      setText: {
        alignSelf: "center",
    
        color: "white",
        fontWeight: "bold",
        fontSize: "150%",
      },
    
      centerText: {
        flex: 1,
        alignSelf: "center",
        justifyContent: "center",
    
        color: "white",
        fontWeight: "bold",
        fontSize: 25,
      },
  });