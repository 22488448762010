
import {
    StyleSheet,
    Dimensions
  } from 'react-native';
  
  export default StyleSheet.create({
    buttonContainer: {
        // flex: 1,
        // position: "absolute",
        ...StyleSheet.absoluteFillObject,
        alignSelf: "center",
        alignContent: "center",
        justifyContent: "center",
        top: 60
        // top: "40%",
        //transform: [{scale: document.body.scrollWidth / 450 < 1 ? document.body.scrollWidth / 450 : 1}]
    },
    shadow: {
        elevation: 10,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2},
        shadowOpacity: .5,
        shadowRadius: 5,
    },

    buttonIcon: {
        height: 30
    },

    button: {
        alignItems: "center",
        justifyContent: "center",
        height: 85,
        flexDirection: "row",

        backgroundColor: "#CE002B",
        borderRadius: 10,
        
        marginBottom: 12,
        marginRight: 30,
        marginLeft: 30,
        
    },

    buttonContentContainer: {
        flexDirection: "row", 
        alignItems: "center", 
        width: "100%",

    },

    buttonImageContainer: {
        width: 50, 
        height: "100%", 
        justifyContent: "center", 
        margin: 20,
        marginRight: 0
    },

    smallText: {
        color: "white",
        fontSize: 15, 
        fontWeight: "bold"
    },

    bigText: {
        color: "white",
        fontSize: 25,
        fontWeight: "600",
    },

    timeText: {
        // textAlign: "right",
        color: "white"
    },
    cruiseInfo: {
        color: "white", 
        fontWeight: "bold", 
        fontSize: 16, 
        // textTransform: "uppercase",
    },
    icon: {
        backgroundColor: "white",
        justifyContent: "center",
        alignItems:"center",
        bottom: 40,
        left: 5,
        margin: 2,
        borderRadius: 30,
        minWidth: 30,
        minHeight: 30
    },
    cardImage: {
        backgroundColor: "gray", 
        width: "100%", 
        height: 310,
        borderRadius: 10
    },

    disabled: {
        position: "absolute",
        width: "100%",
        height: "100%",
        // justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, .0)",
        zIndex: 1
    },
    disabledText: {
        color: "white",
        alignSelf: "center",
        textAlign: "center",
        fontStyle: "italic",
        fontSize: 20,
        marginTop: 70
        // bottom: 100
    },

    cardContainer: {
        width: "100%",
            flexDirection: "row",
            alignSelf: "center",
            marginTop: 10,
        flex: 1,
        backgroundColor: "#0E3A6E", 
        height: 80,
        borderRadius: 10,
        marginRight: 30,
        marginLeft: 30,
        padding: 0,
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden"
    },
    
    placeCard: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    buttonText: {
        position: "absolute",
        justifyContent: "center",
        alignSelf: "center",
        color: "white",
        fontWeight: "bold",
        fontSize: 23,
        top: 15,
        textAlign: "center"
    },
    timerButtons: {
        
    },
    setTimerText: {
        alignSelf: "center",
        color: "white",
        fontWeight: "bold",
        fontSize: 29,
    },
    gradient: {
        flex: 1,
        flexDirection: "row",
        position: "absolute", 
        left: 0, 
        right: 0, 
        top: 0, 
        height: 120,
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 10
      }
  })