import React, { Component } from "react";
import { View, TouchableOpacity, Text } from "react-native";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import PropTypes from "prop-types";

import styles from "./CustomTimerStyles";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import lightBlue from "@material-ui/core/colors/lightBlue";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import MomentUtils from "@date-io/moment";
import moment from "moment";

export default class CustomTimer extends Component {
  constructor(props) {
    super(props);

    this.DATE_FORMAT = "MMMM D, YYYY";
    this.TODAY = moment().format(this.DATE_FORMAT);

    this.DATA = {
      customTimer: localStorage.getItem("customDate"),
    };

    this.state = {
      date: this.DATA.customTimer
        ? moment(this.DATA.customTimer, this.DATE_FORMAT)
        : moment(this.TODAY, this.DATE_FORMAT),
    };

    this.preventBehavior = (e) => {
      e.preventDefault();
    };

    document.addEventListener("touchmove", this.preventBehavior, {
      passive: false,
    });
  }

  static propTypes = {
    onSlideSelected: PropTypes.func.isRequired,
  };

  onSet = () => {
    localStorage.setItem(
      "customDate",
      this.state.date.format(this.DATE_FORMAT)
    );
    this.props.onSlideSelected("departure-timer");
  };

  onReset = async () => {
    await this.setState({ date: moment(this.TODAY, this.DATE_FORMAT) });
    localStorage.setItem(
      "customDate",
      this.state.date.format(this.DATE_FORMAT)
    );
  };

  componentWillUnmount() {
    document.removeEventListener("touchmove", this.preventBehavior, {
      passive: false,
    });
  }

  render() {
    var changeTime = (date) => {
      //console.log(date);
      this.setState({ date: date });
    };

    return (
      <View style={styles.container}>
        <View style={styles.timerContainer}>
          <View style={{ flexDirection: "row", margin: 30 }}>
            <Text style={[styles.centerText]}>SELECT A DATE</Text>

            <TouchableOpacity
              style={{ top: 5 }}
              onPress={() => {
                this.props.onSlideSelected("departure-timer");
              }}
            >
              <MaterialCommunityIcons
                name="close-thick"
                size={30}
                color="white"
              />
            </TouchableOpacity>
          </View>

          <View style={styles.cardContainer}>
            <View style={styles.clockContainer}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={materialTheme}>
                  <DatePicker
                    disablePast
                    variant="static"
                    value={this.state.date}
                    onChange={(date) => changeTime(date)}
                    inputVariant="standard"
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </View>

              {/* SET AND RESET BUTTONS */}
            <View style={styles.buttonsContainer}>
              <TouchableOpacity style={styles.set} onPress={() => this.onSet()}>
                <Text style={styles.setText}> SET </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.reset}
                onPress={() => this.onReset()}
              >
                <Text style={styles.resetText}> RESET </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const materialTheme = createTheme({
  overrides: {
    MuiPickersBasePicker: {
      container: {
      },

      pickerView: {
        alignSelf: "center",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "rgba(41, 100, 147, 0.9)",
        height: 100,
        borderRadius: 5,
        maxWidth: "100%"
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        // fontSize: 15
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        
      },
    },
    MuiPickersDay: {
      day: {
        color: "black",
      },
      daySelected: {
        backgroundColor: "rgba(41, 100, 147, 1)",
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
  },

  palette: {
    primary: {
      main: "rgba(41, 100, 147, 0.9)",
    },
    secondary: {
      main: "rgba(41, 100, 147, 1)",
    },
  },
});
