import React, { Component, useRef } from "react";
import {
  Text,
  TouchableOpacity,
  View
} from "react-native";

import PropTypes from "prop-types";
import styles from "./DisclaimerStyles";
    
export default class Acknowledgement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasAgreed: localStorage.getItem("disclaimerAgreed")
        }

    }

    static propTypes = {
        onSlideSelected: PropTypes.func,
        onAgree: PropTypes.func
    }
    
    componentDidMount() {
        if (this.state.hasAgreed) {
            this.props.onSlideSelected("tours");
            //console.log("disclaimer already checked");
        } else {
            //console.log("disclaimer loaded");
        }
        
    }

    render() {
        
        function Content(props) {
            const listInnerRef = useRef();
            var contentHeaderSize = 20;
    
            const scroll = () => {
                if (listInnerRef.current) {
                    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
                    if (scrollTop + clientHeight + .5 === scrollHeight) {
                      //console.log("reached bottom");
                      props.onScrollToBottom();
                    }
                  }
            }
    
            return (
                    <div ref={listInnerRef} style={{overflow: "scroll"}} onScroll={scroll}>
                            <Text>
                                <Text style={{fontWeight: "bold", fontSize: contentHeaderSize}}>Web Privacy Policy</Text>{"\n\n"}
                                This privacy policy contains information about what data is collected and how that information is used
                                when using the website tour.porttb.com and only applies to tour.porttb.com. This policy is subject to
                                change without notice. When users follow a link to another website, they are subject to the privacy policy
                                of that website. Users should check the privacy policies of each of the other sites for information about
                                how the other websites handle data collection.{"\n\n"}
                                Port Tampa Bay built tour.porttb.com to help cruise passengers tour Tampa while waiting for their cruise
                                ship to depart and relies on data obtained from many sources and cannot guarantee the accuracy of the
                                information provided. Port Tampa Bay does not collect the users personal data and only collects
                                information to improve the content of the website.{"\n\n"}
                                While Port Tampa Bay attempts to provide an error free website, it recognizes the possibility of errors and
                                therefore makes not representations as to the quality, accuracy, completeness, timeliness, reliability or
                                suitability of the information provided on or by the web site, and denies any expressed or implied
                                warranty. In no event shall Port Tampa Bay be liable for any special, indirect or consequential damage or
                                any damages whatsoever resulting from loss of use, data or the possibility of passengers missing their
                                cruise departure.{"\n\n"}
                                <Text style={{fontWeight: "bold", fontStyle: "italic"}}>It is solely the responsibility of the users of this website to ensure that they adhere to the cruise line
                                departure time to ensure that they arrive on time for their departing cruise.</Text>{"\n\n\n"}
    
                                <Text style={{fontWeight: "bold", fontSize: contentHeaderSize}}>Collection and Handling of Information</Text>{"\n\n"}
                                When users visit tour.porttb.com, non-personal data about the users visit may be automatically collected.
                                This may include information such as: {"\n"}
                                - IP address and domain {"\n"}
                                - Operating system {"\n"}
                                - Type of browser {"\n"}
                                - Cookies {"\n\n"}
                                <Text style={{fontWeight: "bold", fontSize: contentHeaderSize}}>Use of Cookies</Text> {"\n\n"}
                                Cookies or similar technology is an industry standard that this website uses to provide useful features for
                                the users. Cookies or similar technology can make the Internet more useful by storing information about
                                the users preferences on a particular website. {"\n\n"}
                                Cookies or other similar technology may transfer information from this website to the users hard drive for
                                record-keeping purposes. {"\n\n"}
                                Cookies do not personally identify the user, although they do identify the user’s computer. Most
                                browsers are initially set up to accept cookies; however, users can set their browsers to refuse cookies.
                                Doing so however, may prevent the user from using this website. {"\n\n"}
    
                                Port Tampa Bay may use Internet cookies to enhance the user’s use of the website. No personally
                                identifiable information is collected from the users who use this website or who download information
                                from it. Port Tampa Bay maintains logs of user activity to help manage the website and information
                                stored in these logs or in cookies does not identify the user personally. The logs stores things such as the
                                domain name and IP address from which the user accessed the site, pages visited, and the address of the
                                site that the user linked to us from. Port Tampa Bay uses this information to learn how many visitors we
                                have, where they are coming from, which parts of the web site are of most interest to visitors, and other
                                facts that will help us improve the web site. {"\n\n"}
                                <Text style={{fontWeight: "bold", fontSize: contentHeaderSize}}>Public Records</Text> {"\n\n"}
                                Port Tampa Bay is governed by the state of Florida public records laws. This means that information
                                collected may be subject to public records.
                            </Text>
                            </div>
            );
        }


        return(
            <View style={styles.container}>
                <View style={styles.userAgreement}>

                    <View style={{flexDirection: "row", marginBottom: 20}}>
                        <Text style={[styles.centerText]}>
                        DISCLAIMER
                        </Text>
                    </View>

                    <View style={styles.contentContainer}>
                        <View style={styles.textContainer} >
                            <Content onScrollToBottom={() => this.setState({scrolledToBottom: true})}/>
                        </View>

                        
                        <View style={{flexDirection: "column"}}>
                        <TouchableOpacity style={styles.agree} onPress={() => {this.props.onSlideSelected("tours"); localStorage.setItem("disclaimerAgreed", true)}}>
                            <Text style={styles.setText}> I AGREE </Text>
                        </TouchableOpacity>
                        </View>
                    </View>

                </View>
            </View>
        )
    }
}