import RequestData from "../RequestData";
export default class RequestDestinations extends RequestData {
  constructor() {
    super();

    this.DATA = {};

    const token = "T13LSz_CfYE6BCuZtnveKoVkss-Ss_5UidKjkWBzTK0";

    this.content_type = "location";
    this.setContentType = (type) => {
      this.content_type = type;
      this.path = `/spaces/2e3h70gj067y/environments/master/entries?access_token=${token}&content_type=${this.content_type}`;
    }

    this.host = "https://cdn.contentful.com";
    this.path = `/spaces/2e3h70gj067y/environments/master/entries?access_token=${token}&content_type=${this.content_type}`;
  }

  getDestinations(callback) {
    this.setContentType("location");
    this.getItems("location", (items, data) => {
      callback(items, data);
    })
  }

  getTerminals(callback) {
    this.setContentType("terminal");
    this.getItems("terminal", (items, data) => {
      callback(items, data);
    });
  }

  getRoute(callback) {
    this.setContentType("tour");
    this.getItems("route", (items, data) => {
      callback(items, data);
    });
  }

  getItems(key, callback) {
    var items = [];

    this.getData(key, (data) => {
      items = data.items;
      callback(items, data);
    });
  }

  filterByID(id, assets) {
    var urls = assets.filter((asset) => {
      return asset.sys.id == id;
    });
    return urls;
  }

  getPhotoByID(id, assets) {
    var assets = this.filterByID(id, assets);
    var url = assets[0].fields.file.url;
    return url;
  }

  getPhotoUrlById(id, assets) {  
    const found = assets.find(asset => asset.sys.id == id);    
    return `https://${found.fields.file.url}?w=200`;  
  }

  getPhotosByIndex(index, data) {
      var imageIds = data.items[index].fields.images;
      var assets = data.includes.Asset;

      var imageUrls = [];
      imageIds.map((item) => {
        var url = this.getPhotoByID(item.sys.id, assets);
        imageUrls.push(url);
      });

      return imageUrls;
  }

  filterByTour(tour, destinations, entries) {
    var id = "";
    switch(tour) {
      case "ybor":
        id = "44uGHe2nzCYRz5mDOqHJh9";
        break;
      default:
        id = "2Gc2QmvmqEHDGu2Xi20WVB";
        break;
    }
    var entry = this.filterByID(id, entries);
    var asset = entry[0].sys.id;

    var filtered = destinations.filter((destination) => {
      return (destination.fields.tour ? destination.fields.tour.sys.id == asset : false) || destination.fields.placeId == "parking";
    });

    return filtered;
  }
}
