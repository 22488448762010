
import {
    StyleSheet
  } from 'react-native';
  
  export default StyleSheet.create({
    container: {
      
        width: "100%",
        height: "100%"
    },

    list: {
    },

    emptyText: {
      alignSelf: "center",
      textAlign: "center",
      fontSize: 20,
      color: "gray",
      width: "80%",
    },

    item: {
      height: 40,
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      borderBottomWidth: 1,
      borderBottomColor: "lightgray",
      borderStyle: "dashed",
      borderRadius: 1
      // backgroundColor: "gray"
    },

    itemText: {
      marginLeft: 10,
      color: "#005e91"
    },

    listContainer: {
      flex: 1,
      
    },

    line: {
      marginTop: 10,
      backgroundColor: "#005e91",
      width: "100%",
      height: 1,
      
    },

    title: {
      color: "#005e91",
      fontSize: 16,
      fontWeight: "bold"
    },

    addRoute: {
      backgroundColor: "rgba(41, 100, 147, 1)",
      width: 25,
      height: 25,
      borderRadius: 15,

      justifyContent: "center",
      alignItems: "center"
    },

    cardListContainer: {
      backgroundColor: 'rgba(0, 0, 0, .4)',
      height: 80,
      width: 230,
      padding: 15,
      borderRadius: 15,
      borderWidth: 0,
      borderColor: "white",
      margin: 10,
      shadowRadius: 0
    },

    cardContainer: {
      backgroundColor: 'rgba(0, 0, 0, .4)',
      height: "100%",
      width: "100%",
      padding: 15,
      borderRadius: 15,
      borderWidth: 0,
      borderColor: "white"
      // flexDirection: "row",
      // alignContent: "space-between"

    },

    cardList: {
      flexDirection: "row",
      borderBottomWidth: 2,
      borderBottomColor: "lightgray",
      borderStyle: "dashed",
      borderRadius: 1,
      width: "100%",
      height: "100%"
    },

    cardListContainer: {
      backgroundColor: 'rgba(0, 0, 0, .4)',
      height: "100%",
      width: "100%",
      padding: 15,
      borderRadius: 15,
      borderWidth: 0,
      borderColor: "white",
      // flexDirection: "row",
      // alignContent: "space-between"

    },
    
    cardPressed: {
      backgroundColor: 'rgba(0, 0, 0, .5)',
      height: 200,
      width: 230,
      padding: 15, //24
      borderRadius: 15,
      borderWidth: 2,
      borderColor: "#2b79b5"
    },
    cardImage: {
      width: 200,
      height: 200,
      left: 30,  
      resizeMode: 'contain',
      borderBottomLeftRadius: 24,
      borderBottomRightRadius: 24,
    },
    cardTitle: {
      color: 'white',
      fontSize: 16,
      // bottom: 100,
      fontWeight: "bold",

    },
    blurView: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderRadius: 15
    },
    carousel: {
      flex: 1,
      width: "100%",
      height: "100%",
      overflow: "scroll"
    },
    carouselBlock: {
      //borderRadius: 25,
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
      backgroundColor: 'white',
      position: "absolute",
      width: "100%",
      height: 240,
      
      elevation: 10,
      shadowColor: 'gray',
      shadowOffset: { width: 0, height: -2},
      shadowOpacity: 0.5,
      shadowRadius: 3, 
  }
  });