import * as React from "react";
import { BrowserRouter, Route, Link, Routes, useParams, useLocation, useSearchParams } from "react-router-dom";
import { registerRootComponent } from 'expo';
import { View } from "react-native";

export function useUrlParams() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  export function setUrlParams(props) {

    const [searchParams, setSearchParams] = useSearchParams();

    setSearchParams({"cruiseline": props.param});
    //console.log("search params switched to " + props.params)

    return (
        <View children={props.children}></View>
    )

  }

  export function NewLink(props) {
    let query = useUrlParams();
    let value = query.get(props.params);
    if (!value)
      value = "default";
    
    return (
        <Link to={props.to + "?" + props.params + "=" + value} style={props.style} children={props.children}>
        </Link>
    )
  }
