
import {
    StyleSheet
  } from 'react-native';
  
  export default StyleSheet.create({
      container: {
        ...StyleSheet.absoluteFillObject,
        // backgroundColor: "rgba(0, 67, 125, 0.7)",
        justifyContent: "center",
        alignItems: "center",
        
      },

      blurContainer: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: "rgba(0, 67, 125, 0.7)",
        justifyContent: "center",
        alignItems: "center"
      },
      

      tutorialText: {
        // flex: 1,
        //position: "absolute",
        alignSelf: "center",
        // top: 10,
    
        color: "white",
        fontWeight: "bold",
        fontSize: 20,
      },

      slide: {
        width: 250, 
        height: 400,
        // marginLeft: 10,
      },

      slideContent: {
        width: "100%", 
        height: "100%",
      },

      pagination: {
        // position: "absolute",
        width: "100%",
        height: "100%",
        // flexDirection: "row",
        // justifyContent: "center",
        alignItems: "center",
        bottom: 30
        
      },

      list: {
        flexDirection: "row",
        overflow: "scroll",
        width: "100%",
      },

      modal: {
          width: 250,
          height: 400,
          borderRadius: 10,
          // justifyContent: "center",
          // alignItems: "center",
          overflow: "hidden",
          
          backgroundColor: "white",
          shadowRadius: 25,
          shadowOpacity: .8,
          shadowOffset: {width: 0, height: 5}
          
      }
  });